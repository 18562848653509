<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <p>{{ $t('connection.data.akeneo.load_all_reference_entity') }}</p>
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import {useToast} from "vue-toastification";

export default {
  name: "AkeneoRefEntitiesDataRequest",
  components: {
    GenericDataRequest,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connection: Object,
    errors: Object,
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getAkeneoData(this.connection.id, 'ref_entities', {})
    }
  },
}
</script>

