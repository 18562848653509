<template>
  <div class="d-flex me-lg-5">
    <div class="me-auto">
      <h3 class="me-auto">{{ title }}</h3>
      <slot name="under-title-area"></slot>
    </div>
    <div>
      <slot name="button-area"></slot>
    </div>
  </div>
  <hr>
</template>

<script>
export default {
  name: "HeaderTitle",
  props: {
    title: String
  }
}
</script>

<style scoped>

</style>
