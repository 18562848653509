<template>
  <div class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="title"/>
    <div class="container">
      <p class="lead">{{ $t('guide.bynder_shopify.lets_get_started') }}</p>
      <div class="row">
        <table class="table">
          <tbody>
          <tr>
            <th scope="row">{{ $t('guide.bynder_shopify.step', {number: 1}) }}</th>
            <td>
              <p><strong>{{ $t('guide.bynder_shopify.step.1.title') }}</strong></p>
              <p v-html="$t('guide.bynder_shopify.step.1.1', {
                link: `<a href='/job_instance' title=${$t('job.job_instances')}>${$t('guide.bynder_shopify.this_page')}</a>`
              })"/>
              <p>{{ $t('guide.bynder_shopify.step.1.2') }}</p>
              <img class="guide-image"
                   src="https://www.dataggo.com/wp-content/uploads/2022/11/bynder-x-shopify-step-1.png"
                   alt="image-step-1">
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('guide.bynder_shopify.step', {number: 2}) }}</th>
            <td>
              <p><strong>{{ $t('guide.bynder_shopify.step.2.title') }}</strong></p>
              <p>{{ $t('guide.bynder_shopify.step.2.1') }}</p>
              <p v-html="$t('guide.bynder_shopify.step.2.2', {
                link: `<a href='https://www.dataggo.com/about-dataggo/bynder-x-shop-connector/' title=${$t('guide.bynder_shopify.documentation')}>${$t('guide.bynder_shopify.this_page')}</a>`
              })"/>
              <p>{{ $t('guide.bynder_shopify.step.2.3') }}</p>
              <img class="guide-image"
                   src="https://www.dataggo.com/wp-content/uploads/2022/11/bynder-x-shopify-step-2.png"
                   alt="image-step-2">
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('guide.bynder_shopify.step', {number: 3}) }}</th>
            <td>
              <p><strong>{{ $t('guide.bynder_shopify.step.3.title') }}</strong></p>
              <p>{{ $t('guide.bynder_shopify.step.3.1') }}</p>
              <p>{{ $t('guide.bynder_shopify.step.3.2') }}</p>
              <img class="guide-image"
                   src="https://www.dataggo.com/wp-content/uploads/2022/11/bynder-x-shopify-step-3.png"
                   alt="image-step-3">
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('guide.bynder_shopify.step', {number: 4}) }}</th>
            <td>
              <p><strong>{{ $t('guide.bynder_shopify.step.4.title') }}</strong></p>
              <p>{{ $t('guide.bynder_shopify.step.4.1') }}</p>
              <img class="guide-image"
                   src="https://www.dataggo.com/wp-content/uploads/2022/11/bynder-x-shopify-step-4.png"
                   alt="image-step-3">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from "../../components/Page/Breadcrumbs";
import HeaderTitle from "../../components/Page/Content/HeaderTitle";

export default {
  name: 'BynderShopifySetupView',
  components: {Breadcrumbs, HeaderTitle},
  computed: {
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'BynderShopifySetupView', label: this.$t('guide.bynder_shopify.name'), isCurrent: 'true'},
      ]
    },
    title () {
      return this.$t('guide.bynder_shopify.title');
    },
  },
};
</script>


<style scoped lang="scss">
.guide-image {
  width: 70%;
}
</style>
