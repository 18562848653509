<template>
  <component :is="formatter" v-bind="{val: val}"></component>
</template>

<script>
import NullFormatter from "@/components/Utils/ValueFormatter/NullFormatter";
import DefaultFormatter from "@/components/Utils/ValueFormatter/DefaultFormatter";
import ArrayFormatter from "@/components/Utils/ValueFormatter/ArrayFormatter";
import BooleanFormatter from "@/components/Utils/ValueFormatter/BooleanFormatter";
import JsonFormatter from "@/components/Utils/ValueFormatter/JsonFormatter";

export default {
  name: "ValueFormatter",
  components: {
    NullFormatter,
    DefaultFormatter,
    ArrayFormatter,
    BooleanFormatter,
    JsonFormatter
  },
  props: ['val'],
  computed: {
    formatter() {
      if (undefined === this.val || null === this.val) {
        return 'NullFormatter';
      }

      switch (typeof this.val) {
        case 'string':
          return this.getStringFormatter(this.val)
        case 'boolean':
          return 'BooleanFormatter'
        case 'object':
          return this.getObjectFormatter(this.val)
        default:
          return 'DefaultFormatter'
      }
    },
  },
  methods: {
    getObjectFormatter(val) {
      if (Array.isArray(val)) {
        return 'ArrayFormatter'
      }

      return 'DefaultFormatter';
    },
    getStringFormatter(val) {
      if (this.isJson(val)) {
        return 'JsonFormatter';
      }

      return 'DefaultFormatter';
    },
    isJson(val) {
      try {
        JSON.parse(val);
      } catch (e) {
        return false;
      }
      return true;
    }
  }
}
</script>
