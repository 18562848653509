import {createStore} from 'vuex';
import {auth} from "./auth.module";
import {dataGrid} from "@/store/datagrid.module";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        dataGrid
    }
})
