<template>
  <div class="mt-3">
    <h5>{{ $t('job.jobInstance.imatag.config.bynder_configuration') }}</h5>
    <TextField
        class="w-75"
        id="bynderDomain"
        :label="$t('job.jobInstance.imatag.config.bynder_configuration.domain')"
        v-model="bynderDomain"
    />
    <SecuredVariableField
        id="bynderToken"
        :label="$t('job.jobInstance.imatag.config.bynder_configuration.token')"
        :variable="this.getSecuredVariable('BYNDER_TOKEN')"
        :job-instance="jobInstance"
        :read-only="!jobInstance.id"
    />
    <TextField
        class="w-75"
        id="bynderImatagHash"
        :label="$t('job.jobInstance.imatag.config.bynder_configuration.bynder_imatag_hash')"
        v-model="bynderImatagHash"
        :error="this.getPropertyErrors(['bynder_imatag_watermark', 'bynder_imatag_hash'])"
    />
    <h5>{{ $t('job.jobInstance.imatag.config.imatag_configuration') }}</h5>
    <SecuredVariableField
        id="imatagApiKey"
        :label="$t('job.jobInstance.imatag.config.imatag_configuration.imatag_api_key')"
        :variable="this.getSecuredVariable('IMATAG_API_KEY')"
        :job-instance="jobInstance"
        :read-only="!jobInstance.id"
    />
    <TextField
        class="w-75"
        id="imatagDirectory"
        :label="$t('job.jobInstance.imatag.config.imatag_configuration.imatag_directory')"
        v-model=" imatagDirectory"
        :error="this.getPropertyErrors(['bynder_imatag_watermark', 'imatag_directory'])"
    />
    <TextField
        class="w-75"
        id="imatagBynderMetadata"
        :label="$t('job.jobInstance.imatag.config.imatag_configuration.imatag_bynder_id_metadata')"
        v-model="imatagBynderMetadata"
        :error="this.getPropertyErrors(['bynder_imatag_watermark', 'imatag_bynder_id_metadata'])"
    />
    <h5>{{ $t('job.jobInstance.imatag.config.filters') }}</h5>
    <ToggleField
        class="w-75"
        id="delta"
        :label="$t('job.jobInstance.imatag.config.delta')"
        v-model="delta"
        :error="this.getPropertyErrors(['bynder_imatag_watermark', 'delta'])"
    />
    <BynderFilterFields
        id="filters"
        v-model="conditions"
        :errors="this.getPropertyErrors(['bynder_imatag_watermark', 'conditions'])"
    />
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import TextField from "../../../../Form/TextField";
import ToggleField from "../../../../Form/ToggleField";
import SecuredVariableField from "../SecuredVariableField";
import jobConfigurationMixin from "../../../../../mixins/jobConfigurationMixin";
import BynderFilterFields from "@/components/Job/JobInstance/Configuration/Bynder/BynderFilterFields";

export default {
  name: "ConfigurationForm",
  mixins: [jobConfigurationMixin],
  components: {
    BynderFilterFields,
    SecuredVariableField,
    ToggleField,
    TextField,
  },
  props: {
    modelValue: Object,
    errors: Object,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      jobInstance: this.modelValue,
      configuration: JSON.parse(this.modelValue.configuration),
    }
  },
  computed: {
    bynderDomain: {
      get () {
        return this.getJobConfiguration(["bynder_connection", "domain"]);
      },
      set (v) {
        this.setJobConfiguration(["bynder_connection", "domain"], v)
      },
    },
    bynderImatagHash: {
      get () {
        return this.getJobConfiguration(["bynder_imatag_hash"]);
      },
      set (v) {
        this.setJobConfiguration(["bynder_imatag_hash"], v)
      },
    },
    imatagDirectory: {
      get () {
        return this.getJobConfiguration(["imatag_directory"]);
      },
      set (v) {
        this.setJobConfiguration(["imatag_directory"], v)
      },
    },
    imatagBynderMetadata: {
      get () {
        return this.getJobConfiguration(["imatag_bynder_id_metadata"]);
      },
      set (v) {
        this.setJobConfiguration(["imatag_bynder_id_metadata"], v)
      },
    },
    delta: {
      get () {
        return !!this.getJobConfiguration(["delta"]);
      },
      set (v) {
        this.setJobConfiguration(["delta"], v)
      },
    },
    conditions: {
      get () {
        return this.getJobConfiguration(["conditions"]);
      },
      set (v) {
        this.setJobConfiguration(["conditions"], v)
      },
    },
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>
