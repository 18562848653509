<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <TextField
          id="metaproperty-name"
          class="w-100"
          :label="$t('connection.data.bynder.metaproperty_name')"
          v-model="metapropertyName"
          :required="true"
      />
      <TextField
          id="metaproperty-name"
          class="w-100"
          :label="$t('connection.data.bynder.option_name')"
          v-model="optionName"
      />
      <TextField
          id="metaproperty-name"
          class="w-100"
          :label="$t('connection.data.bynder.external_reference')"
          v-model="externalReference"
      />
      <NumberField
          id="page"
          class="w-100"
          :label="$t('connection.data.bynder.page')"
          v-model="page"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField.vue";
import NumberField from "@/components/Form/NumberField.vue";
import {useToast} from "vue-toastification";

export default {
  name: "BynderMetapropertyOptionsDataRequest",
  components: {
    NumberField,
    TextField,
    GenericDataRequest,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      metapropertyName: '',
      optionName: '',
      externalReference: '',
      page: 1,
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      if (this.metapropertyName === '') {
        this.toast.error(this.$t('connection.data.bynder.invalid_metaproperty_name'));
        return;
      }

      if (this.page <= 0) {
        this.toast.error(this.$t('connection.data.bynder.invalid_page'));
        return;
      }

      return ConnectionService.getBynderData(this.connection.id, 'metaproperty_options', {
        metaproperty_name: this.metapropertyName,
        option_name: this.optionName,
        external_reference: this.externalReference,
        page: this.page
      })
    }
  },
}
</script>

