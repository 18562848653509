const helperMethods = {
    initGrid: (state, gridName) => {
        state[gridName] = {
            page: 1,
            // Filters are transformed into string to avoid JSON decode issues on state init.
            filters: JSON.stringify({})
        }
    },
    updateLocalStorage: (state) => {
        localStorage.setItem('dataGridStates', JSON.stringify(state));
    }
}

export const dataGrid = {
    namespaced: true,
    state: JSON.parse(localStorage.getItem('dataGridStates') || '{}'),
    getters: {
        page: (state) => (gridName) => {
            return state[gridName].page || 1;
        },
        filters: (state) => (gridName) => {
            return JSON.parse(state[gridName].filters || '{}');
        },
    },
    actions: {
        initGrid({commit}, gridName) {
            commit('initGrid', gridName);
        },
        updatePage({commit}, {gridName, page}) {
            commit('updatePage', {gridName: gridName, page: page});
        },
        updateFilters({commit}, {gridName, filters}) {
            commit('updateFilters', {gridName: gridName, filters: filters});
        }
    },
    mutations: {
        initGrid(state, gridName) {
            if (undefined === state[gridName]) {
                helperMethods.initGrid(state, gridName);
                helperMethods.updateLocalStorage(state);
            }
        },
        updatePage(state, {gridName, page}) {
            if (undefined === state[gridName]) {
                helperMethods.initGrid(state, gridName);
            }

            state[gridName].page = page
            helperMethods.updateLocalStorage(state);
        },
        updateFilters(state, {gridName, filters}) {
            if (undefined === state[gridName]) {
                helperMethods.initGrid(state, gridName);
            }

            state[gridName].page = 1;
            state[gridName].filters = JSON.stringify(filters);
            helperMethods.updateLocalStorage(state);
        }
    }
};
