<template>
  <AsyncSelectField
      :id="code.replace('.', '')"
      :label="title"
      v-model="value"
      :multiple="true"
      :on-change="onUpdate"
      :ajax-route="options.route"
      :ajax-search-property="options.searchProperty"
      :ajax-result-id="options.optionIdProperty"
      :ajax-result-label="options.resultLabel"
      :option-translation-prefix="options.optionTranslationPrefix"
  />
</template>

<script>
import AsyncSelectField from "@/components/Form/AsyncSelectField";
export default {
  name: "AsyncSelectFilter",
  components: {AsyncSelectField},
  data() {
    return {
      value: this.baseValue || []
    }
  },
  props: {
    title: String,
    code: String,
    options: Object,
    onUpdate: Function,
    baseValue: Array
  },
}
</script>
