<template>
    <component :is="filterComponent" v-bind="{
    options: conf.options || {}, 
    code: conf.code, 
    title: conf.title, 
    onUpdate: onUpdate, 
    baseValue: value
    }">
    </component>
</template>

<script>
import SelectFilter from "@/components/Utils/Datagrid/Filter/SelectFilter";
import AsyncSelectFilter from "@/components/Utils/Datagrid/Filter/AsyncSelectFilter";
import DateTimeFilter from "@/components/Utils/Datagrid/Filter/DateTimeFilter";

export default {
    name: "Filter",
    components: {
        SelectFilter,
        AsyncSelectFilter,
        DateTimeFilter
    },
    props: {
        gridName: String,
        conf: Object,
        onFilterUpdate: Function,
        value: [Array, String, Number, Object]
    },
    computed: {
        filterComponent() {
            switch (this.conf.type) {
                case 'select':
                    return 'SelectFilter'
                case 'async-select':
                    return 'AsyncSelectFilter'
                case 'datetime':
                    return 'DateTimeFilter'
            }

            return null;
        }
    },
    methods: {
        onUpdate(value) {
            this.onFilterUpdate(this.conf.code, value);
        }
    }
}
</script>
