<template>
  <table v-if="items.length > 0" class="table table-hover">
    <thead>
    <tr>
      <th scope="col">{{ $t('common.id') }}</th>
      <th scope="col" v-if="!hideJobInstanceInfo">{{ $t('job.job_instance') }}</th>
      <th scope="col" v-if="!hideJobInstanceInfo && isGranted('ROLE_ADMIN')">{{ $t('user.property.company.name') }}</th>
      <th scope="col">{{ $t('common.created_at') }}</th>
      <th scope="col">{{ $t('job_execution.property.started_at') }}</th>
      <th scope="col">{{ $t('job_execution.property.finished_at') }}</th>
      <th scope="col">{{ $t('job_execution.property.created_by') }}</th>
      <th scope="col">{{ $t('job_execution.property.status') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in items" :key="item.id" @click="redirectToJobExecutionView(item.id)">
      <td>{{ item.id }}</td>
      <td v-if="!hideJobInstanceInfo">{{ item.jobInstance.label ?? item.jobInstance.code }}</td>
      <td v-if="!hideJobInstanceInfo && isGranted('ROLE_ADMIN')">{{ item.jobInstance.company.name }}</td>
      <td>
        <DateFormatter v-if="item.createdAt" :value="item.createdAt"
                       format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td>
        <DateFormatter v-if="item.executionStartDate" :value="item.executionStartDate"
                       format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td>
        <DateFormatter v-if="item.executionEndDate" :value="item.executionEndDate"
                       format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td>{{ item.createdBy }}</td>
      <td>
        <button class="btn btn-primary position-relative disabled btn-sm" :class="statusClass(item.status)">
          {{ $t('job_execution.status.' + item.status) }}
          <span v-if="countWarnings(item)"
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                            {{ countWarnings(item) }}
                        </span>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div v-if="items.length === 0" class="text-center mt-lg-5">
    {{ $t('job.job_instances.no_job_executions_yet') }}
  </div>
</template>

<script>
import DateFormatter from "@/components/Utils/DateFormatter";
import SecurityMixin from "@/mixins/securityMixin";

export default {
  name: "JobExecutionGrid",
  components: {DateFormatter},
  mixins: [SecurityMixin],
  props: {
    items: Array,
    hideJobInstanceInfo: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    countWarnings(jobExecution) {
      let warningCount = 0;
      for (let stepExecution of jobExecution.stepExecutions) {
        warningCount += stepExecution.warningCount;
      }

      return warningCount;
    },
    statusClass(status) {
      switch (status) {
        case 'waiting':
          return 'btn-info';
        case 'inProgress':
          return 'btn-primary';
        case 'success':
          return 'btn-success';
        case 'warning':
          return 'btn-warning';
        case 'error':
          return 'btn-danger';
        case 'mustStop':
        case 'stopped':
          return 'btn-secondary'
        default:
          return 'btn-light';
      }
    },
    redirectToJobExecutionView(id) {
      this.$router.push('/job_execution/' + id);
    }
  }
}
</script>

<style scoped lang="scss">
button.disabled {
  opacity: 1 !important;
}

table {
  tr {
    cursor: pointer;
  }
}
</style>
