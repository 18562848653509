<template>
  <nav v-if="maxPage() > 1">
    <ul class="pagination justify-content-center pagination-sm">
      <li class="page-item">
        <a class="page-link" href="#" aria-label="First page" @click.prevent="onPageSelection(1)">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li v-for="page in pageNumbers()" :key="page" class="page-item" :class="page === currentPage ? 'active' : ''">
        <a class="page-link" href="#" @click.prevent="onPageSelection(page)">{{ page }}</a>
      </li>
      <li class="page-item">
        <a class="page-link" href="#" aria-label="Next" @click.prevent="onPageSelection(maxPage())">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pager",
  props: {
    currentPage: Number,
    totalCount: Number,
    itemPerPage: {
      type: Number,
      default: 30
    },
    onPageSelection: Function
  },
  methods: {
    maxPage() {
      return Math.ceil(this.totalCount / this.itemPerPage);
    },
    pageNumbers() {
      if (this.currentPage === 1) {
        let pages = [1];
        if (this.maxPage() >= 2) {
          pages.push(2);
        }
        if (this.maxPage() >= 3) {
          pages.push(3);
        }

        return pages;
      }

      if (this.currentPage === this.maxPage()) {
        const pages = [];
        if (this.currentPage > 2) {
          pages.push(this.currentPage - 2);
        }

        pages.push(...[this.currentPage - 1, this.currentPage]);

        return pages;
      }

      const pages = [];
      const previousPage = this.currentPage - 1;
      const nextPage = this.currentPage + 1;
      if (previousPage > 0) {
        pages.push(previousPage);
      }
      pages.push(this.currentPage);
      if (nextPage <= this.maxPage()) {
        pages.push(nextPage);
      }

      return pages;
    }
  }
}
</script>

<style scoped>

</style>
