<template>
  <GenericConnectionData
      :connectionId="connectionId"
      :title="title"
      :type="type"
      :breadcrumbsItems="breadcrumbsItems"
      @update-connection="updateConnection"
  >
    <template v-slot:request-components>
      <div class="col-lg-3 col-md-4">
        <ShopifyProductByIdDataRequest
            id="product-by-id"
            class="mt-3"
            :title="$t('connection.data.shopify.product_by_id')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <ShopifyProductSearchDataRequest
            id="product-by-id"
            class="mt-3"
            :title="$t('connection.data.shopify.product_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <ShopifyProductVariantSearchDataRequest
            id="product-by-id"
            class="mt-3"
            :title="$t('connection.data.shopify.product_variant_search')"
            :connection="connection"
        />
      </div>
    </template>
  </GenericConnectionData>
</template>

<script>
import {useToast} from "vue-toastification";
import securityMixin from "@/mixins/securityMixin";
import GenericConnectionData from "@/views/Connection/Data/GenericConnectionData.vue";
import ShopifyProductByIdDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyProductByIdDataRequest.vue";
import ShopifyProductSearchDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyProductSearchDataRequest.vue";
import ShopifyProductVariantSearchDataRequest
  from "@/views/Connection/Data/Shopify/DataRequest/ShopifyProductVariantSearchDataRequest.vue";

export default {
  name: "ShopifyConnectionData",
  components: {
    ShopifyProductVariantSearchDataRequest,
    ShopifyProductByIdDataRequest,
    ShopifyProductSearchDataRequest,
    GenericConnectionData,
  },
  mixins: [securityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionId: String,
    title: String,
    type: String,
    breadcrumbsItems: Object
  },
  methods: {
    updateConnection(updatedConnection) {
      this.connection = updatedConnection;
    },
  },
  data () {
    return {
      connection: {},
    }
  },
}
</script>

<style scoped>

</style>
