<template>
  <div class="row">
    <div class="text-center text-black login-view">
      <div class="align-items-center h-50 login-header">
        <img :src="getImage('platform-logo.png')" class="w-150">
        <h1 class="title">Connect Platform</h1>
        <span>By Dataggo ©</span>
      </div>

      <div v-if="!requestSent" class="m-auto mt-5">
        <form @submit.prevent="handleSubmit" class="col-lg-4 col-sm-8 m-auto">
          <div class="form-floating mb-4">
            <input v-model="email" type="email" required class="form-control form-control-lg" id="email"
                   placeholder="Email">
            <label for="email">
              {{ $t('security.email') }}
            </label>
          </div>
          <div class="d-grid gap-2 mb-2">
            <button class="btn btn-primary" type="submit" :disabled="loading">
              {{ $t('security.requestPwd') }}
            </button>
          </div>
          <router-link class="nav-link" to="/">
            {{ $t('common.cancel') }}
          </router-link>
        </form>
      </div>

      <div v-if="requestSent" class="m-auto mt-5 pt-3">
        {{ $t('security.requestSent') }}
        <p class="small mb-5 pb-lg-2 mx-auto">
          <router-link class="nav-link" to="/">
            {{ $t('common.home') }}
          </router-link>
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {useToast} from "vue-toastification";
import ResetPasswordService from "../../services/reset-password.service";
import appStyleMixin from "@/mixins/appStyleMixin";

export default {
  name: "ForgetPassword",
  mixins: [appStyleMixin],
  components: {},
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      loading: false,
      requestSent: false,
      email: ''
    };
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.status.loggedIn,
    }),
  },
  created () {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleSubmit () {
      this.loading = true;
      ResetPasswordService.sendResetPasswordLink(this.email, this.getAppStyle()).then(
          () => {
            this.toast.success(this.$i18n.t('security.resetPasswordRequestSent'));
            this.loading     = false;
            this.requestSent = true;
          },
          (error) => {
            console.log(error);
            this.toast.error(this.$i18n.t('An error occurred'));
            this.loading = false;
          }
      );
    },
  },
  beforeMount () {
    if (navigator.language) {
      this.$i18n.locale = navigator.language.split('-')[0];
    }
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Fira+Code');

.title {
  font-family: 'Fira Code', serif;
  font-size: 3.5rem;
}

</style>
