<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-for="item in items"
          :key="item.name"
          class="breadcrumb-item"
          :class="item.isCurrent ? 'active' : ''"
          aria-current="page"
      >
        <router-link
            v-if="item.link"
            class="link-secondary"
            :to="item.link"
        >
          {{ item.label }}
        </router-link>
        <template v-else>
          {{ item.label }}
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    items: Array
  }
}
</script>

<style scoped>

</style>
