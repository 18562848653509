import apiClient from "./api-client";

class ResetPasswordService {
    sendResetPasswordLink (email, appStyle) {
        return apiClient().post('/reset_password_request/send/' + email, {appStyle : appStyle}, {});
    }

    resetPassword (plainPassword, toCheckPlainPassword, token) {
        let parameters = {
            plainPassword: plainPassword,
            toCheckPlainPassword: toCheckPlainPassword,
            token: token
        }

        return apiClient().post('/reset_password_request/reset', parameters, {});
    }
}

export default new ResetPasswordService();
