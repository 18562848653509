<template>
  <div ref="modal" class="modal fade" :id="'warning-modal-' + stepExecutionId" data-bs-backdrop="static"
       data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Step Warnings</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Loader v-if="isLoadingWarnings"/>
          <template v-if="!isLoadingWarnings">
            <div v-for="warning in warnings" :key="warning.id"
                 class="alert alert-warning d-flex align-items-center" role="alert">
              <i class="bi bi-exclamation-diamond me-1"/>
              <div>
                {{ warning.message }}
              </div>
            </div>

            <Loader v-if="isLoadingMoreWarnings"/>
            <div v-if="!isLoadingMoreWarnings && warnings.length < totalCount" class="text-center">
              <button class="btn btn-light" @click="loadMore">
                {{ $t('common.show_more') }}
              </button>
            </div>

          </template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../../Page/Loader";
import JobService from "../../../services/job.service";

export default {
  name: "StepExecutionWaningModal",
  components: {Loader},
  props: {
    stepExecutionId: Number
  },
  data() {
    return {
      isLoadingWarnings: false,
      isLoadingMoreWarnings: false,
      warnings: [],
      totalCount: 0,
      currentPage: 1
    }
  },
  methods: {
    loadMore() {
      this.isLoadingMoreWarnings = true;
      JobService.getStepExecutionWarnings(this.stepExecutionId, this.currentPage + 1)
          .then((response) => {
            this.warnings = this.warnings.concat(response.data['hydra:member']);
            this.currentPage = this.currentPage + 1;
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoadingMoreWarnings = false;
          })
    }
  },
  mounted() {
    this.$refs.modal.addEventListener('show.bs.modal', () => {
      this.isLoadingWarnings = true;
      JobService.getStepExecutionWarnings(this.stepExecutionId)
          .then((response) => {
            this.warnings = response.data['hydra:member'];
            this.totalCount = response.data['hydra:totalItems'];
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoadingWarnings = false;
          })
    })
  }
}
</script>

<style scoped>

</style>
