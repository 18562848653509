import authHeader from './Auth/auth-header';
import apiClient from "./api-client";
import qs from "qs";

class JobService {
    getJobInstances(page = 1, params = {}) {
        let config = {
            headers: authHeader(),
            params: {
                page: page,
                ...params
            }
        }

        return apiClient().get('/api/job_instances', config);
    }

    getJobInstance(code) {
        let config = {
            headers: authHeader(),
        }

        return apiClient().get('/api/job_instances/' + code, config);
    }

    createOrUpdateJobInstance(jobInstance) {
        let config = {
            headers: authHeader(),
        }

        if(jobInstance.companyCode) {
            jobInstance.company = "/api/companies/" + jobInstance.companyCode;
            delete jobInstance.companyCode;
        }

        if (jobInstance.id) {
            return apiClient().patch('/api/job_instances/' + jobInstance.code, jobInstance, config);
        } else {
            return apiClient().post('/api/job_instances', jobInstance, config);
        }
    }

    getLastJobExecutions(jobInstanceId) {
        let config = {
            headers: authHeader(),
            params: {
                "order[id]": 'desc',
                jobInstance: jobInstanceId
            }
        }

        return apiClient().get('/api/job_executions', config);
    }

    async getJobs() {
        let config = {
            headers: authHeader(),
        }

        return apiClient().get('/api/list_jobs', config);
    }

    launchJob(jobInstance, deltaOverride = null) {
        let config = {
            headers: authHeader(),
        }

        let url = '/api/job_instances/' + jobInstance.code + '/add_to_queue';
        if(deltaOverride !== null) {
            url += '?delta_override=' + deltaOverride;
        }

        return apiClient().get(url, config);
    }

    deleteJob(jobInstance) {
        let config = {
            headers: authHeader(),
        }

        return apiClient().delete('/api/job_instances/' + jobInstance.code, config);
    }

    createOrUpdateJobVariable(variable) {
        let config = {
            headers: authHeader(),
        }

        if(variable.jobInstance) {
            variable.jobInstance = "/api/job_instances/" + variable.jobInstance;
        }

        if (variable.id) {
            return apiClient().patch('/api/secured_variables/' + variable.id, variable, config);
        } else {
            return apiClient().post('/api/secured_variables', variable, config);
        }
    }

    deleteJobVariable(id) {
        let config = {
            headers: authHeader(),
        }

        return apiClient().delete('/api/secured_variables/' + id, config);
    }

    getJobExecutions(page = 1, params = {}) {
        let config = {
            headers: authHeader(),
            params: {
                "order[id]": 'desc',
                page: page,
                ...params
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            }
        }

        return apiClient().get('/api/job_executions', config);
    }

    getJobExecution(id) {
        let config = {
            headers: authHeader()
        }

        return apiClient().get('/api/job_executions/' + id, config);
    }

    getExecutionLogs(id) {
        let config = {
            headers: authHeader()
        }

        return apiClient().get('/api/job_executions/' + id + '/download_execution_logs', config);
    }

    getStepExecutionWarnings(stepId, page = 1) {
        let config = {
            headers: authHeader(),
            params: {
                stepExecution: stepId,
                page: page
            }
        }

        return apiClient().get('/api/job_warnings', config);
    }

    stopJobExecution(id) {
        let config = {
            headers: authHeader()
        }

        return apiClient().get('/api/job_executions/' + id + '/stop', config);
    }
}

export default new JobService();
