<template>
  <SelectField
      :id="code"
      :label="title"
      v-model="value"
      :options="options.selectOptions"
      :multiple="true"
      :on-change="onUpdate"
  />
</template>

<script>
import SelectField from "@/components/Form/SelectField";

export default {
  name: "SelectFilter",
  components: {
    SelectField
  },
  data() {
    return {
      value: this.baseValue || []
    }
  },
  props: {
    title: String,
    code: String,
    options: Object,
    onUpdate: Function,
    baseValue: Array
  },
}
</script>
