<template>
  <component :is="connectionEditFormName"
             :breadcrumbsItems="breadcrumbs"
             :type="this.type"
             :connectionId="this.connectionId"
             :title="$t('connection.edit.' + this.type + '.title')"
  />
</template>

<script>
import CompanyForm from "@/components/Company/CompanyForm.vue";
import AkeneoConnectionEditForm from "@/views/Connection/Edit/AkeneoConnectionEditForm.vue";
import BynderConnectionEditForm from "@/views/Connection/Edit/BynderConnectionEditForm.vue";
import ShopifyConnectionEditForm from "@/views/Connection/Edit/ShopifyConnectionEditForm.vue";

export default {
  name: 'ConnectionEdit',
  components: {CompanyForm},
  computed: {
    type() {
      return this.$route.params.type;
    },
    connectionId() {
      return this.$route.params.id;
    },
    connectionEditFormName () {
      switch (this.$route.params.type) {
        case "akeneo":
          return AkeneoConnectionEditForm
        case "bynder":
          return BynderConnectionEditForm
        case "shopify":
          return ShopifyConnectionEditForm
      }

      throw "Invalid connection type";
    },
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'connections', label: this.$i18n.t('connection.plural'), link: '/connections'},
      ]
    }
  }
}
</script>
