<template>
  <div v-if="connections.length > 0" class="row">
    <h4 class="pb-3">{{ title }}</h4>
    <div
        v-for="(connection, index) in connections"
        :key="index"
        class="connection-summary col-xs-12 col-sm-6 col-md-5 col-lg-4 mb-3"
    >
      <component :is="connectionSummaryViewName"
                 :connection="connection"
                 :connectionType="this.connectionType"
                 :hasDataRoute="hasDataRoute"
      />
    </div>
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import AkeneoConnectionSummary from "@/views/Connection/Summary/AkeneoConnectionSummary.vue";
import BynderConnectionSummary from "@/views/Connection/Summary/BynderConnectionSummary.vue";
import ShopifyConnectionSummary from "@/views/Connection/Summary/ShopifyConnectionSummary.vue";
import ConnectionService from "@/services/connection.service";

export default {
  name: "ConnectionSummaries",
  components: {},
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionType: String,
    title: String,
  },
  data () {
    return {
      connections: [],
      isLoading: true,
      totalCount: 0,
      page: 1,
      errors: {},
      success: {},
    }
  },
  computed: {
    connectionSummaryViewName () {
      switch (this.connectionType) {
        case "akeneo":
          return AkeneoConnectionSummary;
        case "bynder":
          return BynderConnectionSummary;
        case "shopify":
          return ShopifyConnectionSummary;
      }

      throw "Invalid connection type";
    },
    hasDataRoute () {
      switch (this.connectionType) {
        case "akeneo":
        case "bynder":
        case "shopify":
          return true;
      }

      throw "Invalid connection type";
    },
  },
  methods: {
    async loadConnections (page) {
      this.isLoading = true;
      let params = {
        page: page,
        itemsPerPage: 30,
      };
      await ConnectionService.getConnections(this.connectionType, params).then(async (response) => {
        this.connections = [...this.connections, ...response.data['hydra:member']];
        this.totalCount  = response.data['hydra:totalItems'];
        if (this.totalCount > page * 30) {
          await this.loadConnections(page + 1);
        }
      });
      this.isLoading = false;
    },
  },
  async beforeMount () {
    this.isLoading = true;
    await this.loadConnections(1);
    this.isLoading = false;
  }
}
</script>

<style lang="scss">

</style>
