<template>
  <Loader v-if="isLoaded"/>
  <div v-if="!isLoaded" class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <form id="job-instance-form" class="mt-3">
      <HeaderTitle :title="title">
        <template v-slot:under-title-area>
          <span v-if="jobInstance.id" class="text-muted">
              {{ $t('common.property.createdAt') }}: <DateFormatter :value="jobInstance.createdAt"/> |
              {{ $t('common.property.updatedAt') }}: <DateFormatter :value="jobInstance.updatedAt"/>
          </span>
        </template>
        <template v-slot:button-area>
          <router-link class="btn btn-light me-1" :to="goBackUrl">
            <i class="bi bi-arrow-left-short"/>
            {{ goBackLinkText }}
          </router-link>
          <button type="button" class="btn btn-success" :disabled="isSubmitting" @click="saveJobInstance">
            <i class="bi bi-check-lg"/>
            {{ $t('common.save') }}
          </button>
        </template>
      </HeaderTitle>
      <div class="container">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#general">
              {{ $t('job.jobInstance.form.tab.generalData') }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#configuration">
              {{ $t('job.jobInstance.form.tab.configuration') }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#alerting">
              {{ $t('job.jobInstance.form.tab.alerting') }}
            </a>
          </li>
          <li class="nav-item" v-if="isGranted('ROLE_CLIENT_ADMIN')">
            <a class="nav-link" data-bs-toggle="tab" href="#advanced_settings">
              {{ $t('job.jobInstance.form.tab.advanced_settings') }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#history" v-if="jobInstance.id">
              {{ $t('versioning.history') }}
            </a>
          </li>
        </ul>
        <div class="tab-content mt-3">
          <div class="tab-pane container active" id="general">
            <ToggleField
                v-model="jobInstance.enabled"
                :label="$t('common.property.enabled')"
                class="mb-3 w-75"
            />
            <TextField
                class="w-75"
                id="code"
                :label="$t('common.property.code')"
                v-model="jobInstance.code"
                :readOnly="Boolean(jobInstance.id)"
                :error="errors.code"
            />
            <TextField
                class="w-75"
                id="label"
                :label="$t('common.property.label')"
                v-model="jobInstance.label"
                :error="errors.label"
            />
            <SelectField
                v-if="jobOptions.length > 0"
                id="jobCode"
                :label="$t('job.jobInstance.property.jobCode')"
                v-model="jobInstance.jobCode"
                :error="errors.jobCode"
                :options="jobOptions"
                :readOnly="Boolean(jobInstance.id)"
                :allowClear="false"
                class="w-75 mb-3"
                :on-change="updateDefaultConf"
            />
            <SelectField
                v-if="companyOptions.length > 0"
                id="company"
                :label="$t('job.jobInstance.property.company_name')"
                v-model="jobInstance.companyCode"
                :error="errors.company"
                :options="companyOptions"
                :readOnly="Boolean(jobInstance.id)"
                :allowClear="false"
                class="w-75 mb-3"
            />
            <TextAreaField
                id="description"
                :label="$t('common.property.description')"
                v-model="jobInstance.description"
                :error="errors.description"
            />
          </div>
          <div class="tab-pane container fade" id="configuration">
            <ConfigurationForm
                v-model="jobInstance"
                ref="configurationForm"
                :errors="errors.configuration"
            ></ConfigurationForm>
          </div>
          <div class="tab-pane container fade" id="alerting">
            <ToggleField
                class="w-75"
                v-model="jobInstance.sendErrorEmail"
                :label="$t('job.jobInstance.property.sendErrorEmail')"
            />
            <TagField
                v-if="jobInstance.sendErrorEmail"
                id="code"
                :placeholder="$t('job.jobInstance.property.addEmailAddress')"
                v-model="jobInstance.errorAlertEmails"
                :readOnly=false
                :error="errors.errorAlertEmails"
                :checkIsEmail=true
                :addOnKey="[13, ',', ';', ' ']"
                class="w-75"
            />
            <ToggleField
                class="w-75"
                v-model="jobInstance.sendWarningEmail"
                :label="$t('job.jobInstance.property.sendWarningEmail')"
            />
            <TagField
                v-if="jobInstance.sendWarningEmail"
                id="code"
                :placeholder="$t('job.jobInstance.property.addEmailAddress')"
                v-model="jobInstance.warningAlertEmails"
                :readOnly=false
                :error="errors.warningAlertEmails"
                :checkIsEmail=true
                :addOnKey="[13, ',', ';', ' ']"
                class="w-75"
            />
          </div>
          <div class="tab-pane container fade" id="advanced_settings">
            <ToggleField
                v-model="jobInstance.allowSimultaneousRun"
                v-if="isGranted('ROLE_ADMIN')"
                :label="$t('job.jobInstance.property.allowSimultaneousRun')"
                class="mb-3 w-75"
            />
            <CronExpressionField
                id="cronExpression"
                v-if="isGranted('ROLE_CLIENT_ADMIN')"
                :label="$t('job.jobInstance.property.cronExpression')"
                v-model="jobInstance.cronExpression"
                :error="errors.cronExpression"
            />
            <SelectField
                id="executionLogVerbosity"
                v-if="isGranted('ROLE_ADMIN')"
                :label="$t('job.jobInstance.property.execution_log_level')"
                v-model="jobInstance.executionLogVerbosity"
                :error="errors.executionLogVerbosity"
                :options="executionLogVerbosityOptions"
                :allowClear="false"
                class="w-75 mb-3"
            />
            <ToggleField
                v-model="jobInstance.showJsonConfiguration"
                v-if="isGranted('ROLE_CLIENT_ADMIN')"
                :label="$t('job.jobInstance.property.showJsonConfiguration')"
                class="mb-3 w-75"
            />
          </div>
          <div class="tab-pane container fade" id="history" v-if="jobInstance.id">
            <HistoryGrid ref="historyGrid" :entity-identifier="jobInstance.code" entity-type="job_instances"/>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import JobService from "../../../services/job.service";
import CompanyService from "@/services/company.service";
import Loader from "../../Page/Loader";
import HeaderTitle from "../../Page/Content/HeaderTitle";
import TextField from "../../Form/TextField";
import TextAreaField from "../../Form/TextAreaField";
import DateFormatter from "../../Utils/DateFormatter";
import {useToast} from "vue-toastification";
import SelectField from "../../Form/SelectField";
import CronExpressionField from "../../Form/CronExpressionField";
import ToggleField from "../../Form/ToggleField";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import TagField from "@/components/Form/TagField";
import {mapState} from "vuex";
import UserService from "@/services/user.service";
import HistoryGrid from "@/components/Versioning/HistoryGrid";
import securityMixin from "@/mixins/securityMixin";
import ConfigurationForm from "./Configuration/ConfigurationForm";

export default {
  name: "JobInstanceForm",
  components: {
    ConfigurationForm,
    HistoryGrid,
    TagField,
    Breadcrumbs,
    ToggleField,
    CronExpressionField,
    SelectField,
    DateFormatter,
    TextAreaField,
    TextField,
    HeaderTitle,
    Loader,
  },
  mixins: [securityMixin],
  props: {
    jobInstanceCode: String
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      isLoaded: false,
      jobInstance: {},
      isSubmitting: false,
      errors: {},
      jobOptions: [],
      jobDefaultConfigurations: [],
      companyOptions: [],
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.auth.user
    }),
    goBackUrl () {
      if (this.jobInstanceCode) {
        return '/job_instance/' + this.jobInstanceCode + '/view';
      }

      return '/job_instance';
    },
    title () {
      if (this.jobInstance.label ?? this.jobInstance.code) {
        return this.$t('job.job_instances') + " - " + (this.jobInstance.label ?? this.jobInstance.code);
      } else {
        return this.$t('job.job_instances');
      }
    },
    breadcrumbs () {
      let currentPageLabel = this.jobInstance.code ? this.jobInstance.code : this.$i18n.t('job.job_instance_creation');
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'job_instances', label: this.$i18n.t('job.job_instances'), link: '/job_instance'},
        {name: 'job_instances', label: currentPageLabel, isCurrent: 'true'},
      ]
    },
    goBackLinkText () {
      if (this.jobInstance.id) {
        return this.$i18n.t('common.go_back');
      }

      return this.$i18n.t('common.cancel');
    },
    executionLogVerbosityOptions () {
      return [
        {'id': 1, 'text': this.$t('job.jobInstance.property.execution_log_level.option.normal')},
        {'id': 2, 'text': this.$t('job.jobInstance.property.execution_log_level.option.verbose')},
        {'id': 3, 'text': this.$t('job.jobInstance.property.execution_log_level.option.very_verbose')},
        {'id': 4, 'text': this.$t('job.jobInstance.property.execution_log_level.option.debug')}
      ]
    },
  },
  methods: {
    saveJobInstance () {
      this.isSubmitting = true;
      JobService.createOrUpdateJobInstance({...this.jobInstance})
          .then((response) => {
            this.errors = {};
            this.toast.success(this.$i18n.t('common.successSubmission'));
            this.jobInstance = response.data;
            if (undefined !== this.jobInstance.company) {
              this.jobInstance.companyCode = this.jobInstance.company.code;
            }
            if (undefined !== this.$refs.historyGrid) {
              this.$refs.historyGrid.loadVersioning();
            }
            if (!this.jobInstanceCode) {
              location.replace("/job_instance/" + this.jobInstance.code + "/edit");
            }
          })
          .catch((error) => {
            this.errors = {};
            if (error.response && error.response.data) {
              this.toast.error(this.$i18n.t(error.response.data['hydra:title'] ?? error.response.data['message']));
              for (const violation of error.response.data.violations) {
                const propertyPath = violation.propertyPath.split('.');
                this.errors = this.updateObject(this.errors, propertyPath, violation.message);
              }
            } else {
              this.toast.error(this.$i18n.t('common.errorOccurred'));
            }
          })
          .finally(() => {
            this.isSubmitting = false;
            this.$refs.configurationForm.refreshComponent();
          });
    },
    updateObject (obj, path, value) {
      const lastKey      = path.pop();
      const nestedObj    = path.reduce((acc, curr) => {
        if (!acc[curr]) {
          acc[curr] = {};
        }
        return acc[curr];
      }, obj);
      nestedObj[lastKey] = value;
      return obj;
    },
    updateDefaultConf (v) {
      this.jobInstance.jobCode       = v;
      this.jobInstance.configuration = this.jobDefaultConfigurations[this.jobInstance.jobCode];
      if (undefined !== this.$refs.configurationForm) {
        this.$refs.configurationForm.refreshComponent();
      }
    }
  },
  async beforeMount () {
    this.isLoaded = true;
    if (this.jobInstanceCode) {
      await JobService.getJobInstance(this.jobInstanceCode)
          .then((response) => {
            this.jobInstance = response.data;
            if (undefined !== this.jobInstance.company) {
              this.jobInstance.companyCode = this.jobInstance.company.code;
            }
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    }

    if (undefined === this.jobInstance.company) {
      await UserService.getUserFromId(this.currentUser.id)
          .then((response) => {
            this.jobInstance.companyCode = response.data.company.code;
          })
          .catch(error => console.log(error));
    }

    if (this.isGranted('ROLE_ADMIN')) {
      await CompanyService.getCompaniesList()
          .then((response) => {
            for (let company of response.data['hydra:member']) {
              this.companyOptions.push({
                id: company.code,
                text: company.name
              });
            }
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    }

    await JobService.getJobs()
        .then((response) => {
          for (let job of response.data['hydra:member']) {
            this.jobDefaultConfigurations[job.code] = job.defaultConf;
            this.jobOptions.push({
              id: job.code,
              text: this.$i18n.t('job.' + job.code) + ' (' + job.connector + ')'
            });
          }
        })
        // TODO Handle error better than this.
        .catch(error => console.log(error));

    this.isLoaded = false;
  }
}
</script>
