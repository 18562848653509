<template>
  <div class="card">
    <div class="mapping-summary">
      <div class="card-body" @click="openModal">
        <h5 class="card-title">
            {{ productSkuMetadata }}
        </h5>
        <p class="card-text">
          {{ $t('form.bynderShopify.mapping.conditions.count', {n: conditions.length}) }}
        </p>
        <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
          <span> {{ formattedMessages }} </span>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t('form.bynderShopify.mapping.card.label', {code: productSkuMetadata}) }}
            </h5>
          </div>
          <div class="modal-body">
            <TextField
                class="w-75"
                id="productSkuMetadata"
                :label="$t('job.jobInstance.shopify.config.bynder_configuration.sku_metadata')"
                v-model="productSkuMetadata"
                :required="true"
                :error="getErrorMessage('productSkuMetadata')"
                :tooltip="$t('job.jobInstance.bynderShopify.config.shopify_product.sku_metadata')"
            />
            <TextField
                class="w-75"
                id="variantSkuMetadata"
                :label="$t('job.jobInstance.shopify.config.bynder_configuration.variant_metadata')"
                v-model="variantSkuMetadata"
                :error="getErrorMessage('variantSkuMetadata')"
                :tooltip="$t('job.jobInstance.bynderShopify.config.shopify_product.variant_metadata')"
            />
            <TextField
                class="w-75"
                id="productSkuSeparator"
                :label="$t('job.jobInstance.shopify.config.bynder_configuration.sku_separator')"
                v-model="productSkuSeparator"
                :error="getErrorMessage('productSkuSeparator')"
                :tooltip="$t('job.jobInstance.bynderShopify.config.shopify_product.sku_separator')"
            />
            <TextField
                class="w-75"
                id="productAltMetadata"
                :label="$t('job.jobInstance.shopify.config.bynder_configuration.image_alt')"
                v-model="imageAltMetadata"
                :error="getErrorMessage('imageAltMetadata')"
                :tooltip="$t('job.jobInstance.bynderShopify.config.shopify_product.alt_text')"
            />
            <TextField
                class="w-75"
                id="imagePositionMetadata"
                :label="$t('job.jobInstance.shopify.config.bynder_configuration.image_position')"
                v-model="imagePositionMetadata"
                :error="getErrorMessage('imagePositionMetadata')"
                :tooltip="$t('job.jobInstance.bynderShopify.config.shopify_product.position')"
            />
            <TagField
                :addOnKey="[13, ' ', ',', ';']"
                :label="$t('job.jobInstance.shopify.config.bynder_configuration.url_presets')"
                class="mt-3 w-75"
                id="productUrlPresets"
                v-model="urlPresets"
                :required="true"
                :error="getErrorMessage('urlPresets')"
                :placeholder="$t('job.jobInstance.shopify.config.bynder_configuration.add_url_presets')"
            />
            <ToggleField
                class="w-75"
                id="deleteNonBynderImages"
                :label="$t('job.jobInstance.bynderShopify.config.shopify_product.deleteNonBynderImages.label')"
                v-model="deleteNonBynderImages"
                :error="getErrorMessage('deleteNonBynderImages')"
            />
            <ToggleField
                class="w-75"
                id="associateAllVariants"
                :label="$t('job.jobInstance.bynderShopify.config.shopify_product.associateAllVariants.label')"
                v-model="associateAllVariants"
                :error="getErrorMessage('associateAllVariants')"
            />
            <h6 class="mt-3 mb-0">{{ $t('job.jobInstance.shopify.config.filters') }}</h6>
            <BynderFilterFields
                id="conditions"
                v-model="conditions"
                :errors="getErrorMessage('conditions')"
            />
          </div>
          <div class="modal-footer">
            <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
              <span> {{ formattedMessages }} </span>
            </div>
            <button @click="removeMapping" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-trash2" aria-hidden="true"/>
              {{ $t('common.remove') }}
            </button>
            <button @click="saveMapping" class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-check" aria-hidden="true"/>
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import TextField from "@/components/Form/TextField";
import BynderFilterFields from "@/components/Job/JobInstance/Configuration/Bynder/BynderFilterFields";
import TagField from "@/components/Form/TagField.vue";
import ToggleField from "@/components/Form/ToggleField.vue";

export default {
  name: "ProductMappingField",
  components: {
    ToggleField,
    TagField,
    TextField,
    BynderFilterFields,
  },
  props: {
    id: String,
    mapping: Object,
    errors: Object,
  },
  data () {
    return {
      modal: null,
      productSkuMetadata: this.mapping.productSkuMetadata ?? "",
      variantSkuMetadata: this.mapping.variantSkuMetadata ?? "",
      productSkuSeparator: this.mapping.productSkuSeparator ?? "",
      imageAltMetadata: this.mapping.imageAltMetadata ?? "",
      imagePositionMetadata: this.mapping.imagePositionMetadata ?? "",
      conditions: this.mapping.conditions,
      urlPresets: this.mapping.urlPresets,
      deleteNonBynderImages: this.mapping.deleteNonBynderImages ?? false,
      associateAllVariants: this.mapping.associateAllVariants ?? false,
      errorMessage: this.error,
      formErrors: {},
    }
  },
  computed: {
    formattedMessages () {
      let formattedMessages = "";
      if (this.errors && Object.keys(this.errors).length !== 0) {
        for (const key in this.errors) {
          const message = typeof this.errors[key] === 'string' ? this.errors[key] : 'invalid'
          formattedMessages += `${key}: ${message}\n`;
        }
      }

      if (Object.keys(this.formErrors).length !== 0) {
        for (const key in this.formErrors) {
          formattedMessages += `${key}: ${this.formErrors[key]}\n`;
        }
      }

      return formattedMessages;
    },
  },
  methods: {
    openModal () {
      this.modal.show();
    },
    saveMapping (e) {
      e.preventDefault();

      this.formErrors = {};
      if (this.productSkuMetadata === "" ) {
        this.formErrors.productSkuMetadata = this.$t('common.isRequired');

        return;
      }

      this.modal.hide();

      let saveData = {
        conditions: this.conditions,
        urlPresets: this.urlPresets,
        deleteNonBynderImages: this.deleteNonBynderImages,
        associateAllVariants: this.associateAllVariants,
        productSkuMetadata: this.productSkuMetadata,
        variantSkuMetadata: this.variantSkuMetadata,
        productSkuSeparator: this.productSkuSeparator,
        imageAltMetadata: this.imageAltMetadata,
        imagePositionMetadata: this.imagePositionMetadata,
      }

      this.$emit('mapping-save', saveData);
    },
    removeMapping (e) {
      if (confirm(this.$t('form.bynderShopify.mapping.delete_confirmation'))) {
        this.modal.hide();
        this.$emit('mapping-remove');
      }

      e.preventDefault();
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
  },
  mounted () {
    this.modal = new Modal(this.$refs.exampleModal)
    if (this.productSkuMetadata === "") {
      this.modal.show();
    }
  },
}
</script>

<style lang="scss">

.mapping-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
}

</style>
