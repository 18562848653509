const jobConfigurationMixin = {
    methods: {
        getJobConfiguration (configurationPath, defaultValue = '') {
            return configurationPath.reduce((acc, curr) => {
                if (acc && acc[curr]) {
                    return acc[curr];
                } else {
                    return defaultValue;
                }
            }, this.configuration);
        },
        setJobConfiguration (configurationPath, value) {
            let newConfiguration         = this.configuration;
            const lastKey                = configurationPath.pop();
            const nestedConfiguration    = configurationPath.reduce((acc, curr) => {
                if (!acc[curr]) {
                    acc[curr] = {};
                }
                return acc[curr];
            }, newConfiguration);
            nestedConfiguration[lastKey] = value;

            this.jobInstance.configuration = JSON.stringify(newConfiguration);
            this.$emit(`update:modelValue`, this.jobInstance);
        },
        getPropertyErrors(propertyPath) {
            if(this.errors === undefined) {
                return null;
            }

            return propertyPath.reduce((acc, curr) => {
                if (acc && acc[curr]) {
                    return acc[curr];
                } else {
                    return null;
                }
            }, this.errors);

        },
        getSecuredVariable (code) {
            let variable = {
                code: code,
                value: '',
            };

            if (this.jobInstance.securedVariables) {
                variable = this.jobInstance.securedVariables.filter((variable) => variable.code === code)[0] ?? variable;
            }

            return variable;
        }
    }
}

export default jobConfigurationMixin
