<template>
  <Loader v-if="!isLoaded"/>
  <div v-if="isLoaded" class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('job.execution_tracker')">
      <template v-slot:under-title-area>
        <p class="d-block text-muted">
          {{ totalCount }} {{ $t('common.results') }}
        </p>
      </template>
    </HeaderTitle>

    <Filters :filters-conf="gridFiltersConf" :on-filter="filter" :base-values="filters" :grid-name="gridName"/>
    <div v-if="0 === rows.length" class="text-center mt-lg-5">
      {{ $t('job.job_instances.no_job_executions_yet') }}
    </div>

    <Loader v-if="isGridLoading"/>
    <div v-if="!isGridLoading && rows.length > 0">
      <Pager :current-page="page" :on-page-selection="changePage" :total-count="totalCount"/>
      <JobExecutionGrid :items="rows"/>
      <Pager :current-page="page" :on-page-selection="changePage" :total-count="totalCount"/>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Page/Loader";
import JobService from "@/services/job.service";
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import JobExecutionGrid from "@/components/Job/JobExecution/JobExecutionGrid";
import Pager from "@/components/Utils/Pager";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import Filters from "@/components/Utils/Datagrid/Filters";
import {mapActions} from "vuex";
import SecurityMixin from "@/mixins/securityMixin";

export default {
  name: "JobExecutionIndex",
  components: {Filters, Breadcrumbs, Pager, JobExecutionGrid, HeaderTitle, Loader},
  mixins: [SecurityMixin],
  data() {
    return {
      gridName: 'jobExecutionGrid',
      isLoaded: false,
      isGridLoading: false,
      rows: [],
      totalCount: 0,
      page: 1,
      filters: {},
      timer: null
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'execution_tracker', label: this.$i18n.t('job.execution_tracker'), isCurrent: true},
      ]
    },
    gridFiltersConf() {
      let conf = [
        {
          code: 'jobInstance',
          type: 'async-select',
          title: this.$i18n.t('job.job_instance'),
          options: {
            route: '/api/job_instances',
            searchProperty: 'code',
          }
        },
        {
          code: 'status',
          type: 'select',
          title: this.$i18n.t('job_execution.property.status'),
          options: {
            selectOptions: [
              {id: 'success', text: 'job_execution.status.success'},
              {id: 'warning', text: 'job_execution.status.warning'},
              {id: 'waiting', text: 'job_execution.status.waiting'},
              {id: 'inProgress', text: 'job_execution.status.inProgress'},
              {id: 'error', text: 'job_execution.status.error'},
              {id: 'mustStop', text: 'job_execution.status.mustStop'},
              {id: 'stopped', text: 'job_execution.status.stopped'}
            ]
          }
        },
        {
          code: 'createdBy',
          type: 'async-select',
          title: this.$i18n.t('job_execution.property.created_by'),
          options: {
              route: '/api/users',
              searchProperty: 'username',
              optionIdProperty: 'username',
              resultLabel: 'username',
          }
        },
        {
            code: 'createdAt',
            type: 'datetime',
            title: this.$i18n.t('common.created_at')
        },
        {
            code: 'executionStartDate',
            type: 'datetime',
            title: this.$i18n.t('job_execution.property.started_at')
        },
        {
            code: 'executionEndDate',
            type: 'datetime',
            title: this.$i18n.t('job_execution.property.finished_at')
        }
      ];

      if(this.isGranted('ROLE_ADMIN')) {
        conf.push({
          code: 'jobInstance.company',
          type: 'async-select',
          title: this.$i18n.t('user.property.company.name'),
          options: {
            route: '/api/companies',
            searchProperty: 'code',
          }
        });
      }

      return conf;
    }
  },
  methods: {
    ...mapActions({
      initGrid: "dataGrid/initGrid",
      updatePage: "dataGrid/updatePage",
      updateFilters: "dataGrid/updateFilters"
    }),
    changePage(page) {
      this.isGridLoading = true;
      this.page = page;
      this.updatePage({gridName: this.gridName, page: page});
      JobService.getJobExecutions(page, this.filters)
          .then((response) => {
            this.rows = response.data['hydra:member'];
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error))
          .finally(() => {
            this.isGridLoading = false;
          });
    },
    async filter(filters) {
      this.isGridLoading = true;
      this.page = 1;
      this.filters = filters;
      this.updateFilters({gridName: this.gridName, filters: filters});
      await JobService.getJobExecutions(1, filters)
          .then((response) => {
            this.rows = response.data['hydra:member'];
            this.totalCount = response.data['hydra:totalItems'];
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
      this.isGridLoading = false;
    },
    clickOnRowAction(row) {
      this.$router.push('/job_instance/' + row.code + '/view');
    },
    refreshGrid() {
      JobService.getJobExecutions(this.page, this.filters)
          .then((response) => {
            this.rows = response.data['hydra:member'];
            this.totalCount = response.data['hydra:totalItems'];
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    }
  },
  beforeMount() {
    this.isLoaded = false;
    this.initGrid(this.gridName);
    this.page = this.$store.getters['dataGrid/page'](this.gridName);
    this.filters = this.$store.getters['dataGrid/filters'](this.gridName);
    JobService.getJobExecutions(this.page, this.filters)
        .then((response) => {
          this.rows = response.data['hydra:member'];
          this.totalCount = response.data['hydra:totalItems'];
          this.isLoaded = true;
        })
        // TODO Handle error better than this.
        .catch(error => console.log(error));
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.refreshGrid()
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>
