<template>
  <Loader v-if="isLoading ||isActivating"/>
  <div v-if="!isLoading && !isActivating" class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('akeneo_app.page.view.title')">
    </HeaderTitle>

    <div>
      <p>{{ $t('akeneo_app.page.view.activation_message', {url: getPimUrl()}) }}</p>
      <button :disabled="isActivating" class="btn btn-success me-1" @click.prevent="activate">
        <i class="bi bi-shield-check"/>
        {{ $t('akeneo_app.page.view.button.activate') }}
      </button>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/Page/Loader"
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import AkeneoAppService from "@/services/akeneo-app.service";
import {useToast} from "vue-toastification";

export default {
  name: "AkeneoAppActivateView",
  components: {Breadcrumbs, HeaderTitle, Loader},
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      isLoading: true,
      isActivating: false,
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
      ]
    }
  },
  methods: {
    getPimUrl () {
      return this.$route.query.pim_url;
    },
    activate () {
      this.isActivating = true;

      AkeneoAppService.activate(this.getPimUrl())
        .then((response) => {
          if (response.data.authorizationUrl) {
            window.location.href = encodeURI(response.data.authorizationUrl);
          } else {
            this.toast.error(this.$i18n.t('common.errorOccurred'));
          }
        })
        .catch(error => {
          console.log(error);
          this.toast.error(this.$i18n.t('common.errorOccurred'));
          this.isActivating = false;
        });
    },
  },
  beforeMount () {
    this.isLoading = false;
  },
}
</script>
