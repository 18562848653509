<template>
  <AsyncSelectField
      :id="id"
      :label="title"
      v-model="value"
      :multiple="false"
      :on-change="onUpdate"
      :ajax-route="route"
      :ajax-search-property="'label'"
      :ajax-result-id="'id'"
      :ajax-result-label="'label'"
      :ajax-default-query="defaultQuery"
      :error="error"
  />
</template>

<script>
import AsyncSelectField from "@/components/Form/AsyncSelectField";

export default {
  name: "ConnectionSelectField",
  components: {AsyncSelectField},
  data () {
    return {
      value: [this.baseValue] || []
    }
  },
  props: {
    id: String,
    title: String,
    error: String,
    code: String,
    type: String,
    onUpdate: Function,
    baseValue: String,
    companyCode: {
      type: String,
      default: ""
    },
  },
  computed: {
    route() {
      return '/api/' + this.type + '_connections'
    },
    defaultQuery() {
      if(this.companyCode === "") {
        return {}
      }

      return {
        'company.code': this.companyCode
      }
    }
  }
}
</script>
