<template>
  <div class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('common.configuration')">
    </HeaderTitle>
    <div class="container w-75">
      <div class="row row-cols-1 row-cols-md-4 g-4">
        <div class="col">
          <div class="card" @click="this.$router.push('/users')">
            <div class="card-body">
              <h5 class="card-title">
                <i class="bi bi-people"></i>
                {{ $t('user.plural') }}
              </h5>
              <p class="card-text">
                {{ $t('user.configuration.description') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col" v-if="isGranted('ROLE_ADMIN')">
          <div class="card" @click="this.$router.push('/companies')">
            <div class="card-body">
              <h5 class="card-title">
                <i class="bi bi-building"></i>
                {{ $t('company.plural') }}
              </h5>
              <p class="card-text">
                {{ $t('company.configuration.description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import SecurityMixin from "@/mixins/securityMixin";

export default {
  name: "ConfigurationMenu",
  components: {Breadcrumbs, HeaderTitle},
  mixins: [SecurityMixin],
  computed: {
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'configuration', label: this.$i18n.t('common.configuration'), isCurrent: true},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
  >:hover {
    border: 1px solid;
  }
}
</style>