<template>
  <UserForm :user-id="$route.params.id" :title="$t('user.view.userEdit')" :breadcrumbs-items="breadcrumbs"/>
</template>

<script>
import UserForm from "../../components/User/UserForm";

export default {
  name: 'UserEdit',
  components: {UserForm},
  computed: {
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'configuration', label: this.$i18n.t('common.configuration'), link: '/configuration'},
        {name: 'users', label: this.$i18n.t('user.plural'), link: '/users'},
        {name: 'id', label: this.$route.params.id, isCurrent: true},
      ]
    }
  }
};
</script>
