<template>
  <Loader v-if="isLoaded"/>
  <div v-if="!isLoaded" class="container-fluid">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <form id="user-account-form" class="mt-3" @submit.prevent="onSubmit()">
      <HeaderTitle :title="computedTitle">
        <template v-slot:button-area>
          <button v-if="company.code" type="button" class="m-1 btn btn-danger" :disabled="isDeleting" data-bs-toggle="modal"
                  data-bs-target="#confirm-deletion">
            <i class="bi bi-trash2" aria-hidden="true"/>
            {{ $t('common.delete') }}
          </button>
          <ConfirmationModal modal-id="confirm-deletion" title="Confirm deletion" :action="deleteCompany">
            <template v-slot:content>
              <i class="bi bi-exclamation-circle-fill"></i>
              {{ $t('company.confirm.deletion_message') }}
              <br/><br/>
              {{ $t('common.please_confirm') }}
            </template>
          </ConfirmationModal>
          <button type="submit" class="btn btn-success" :disabled="isSubmitting">
            <i class="bi bi-check-lg"/>
            {{ $t('common.save') }}
          </button>
        </template>
      </HeaderTitle>
      <div class="container">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#general">
              {{ $t('company.form.tab.general') }}
            </a>
          </li>
        </ul>
        <div class="tab-content mt-3">
          <div class="tab-pane container active" id="general">
            <TextField
                class="w-75"
                id="code"
                :label="$t('company.property.code')"
                v-model="company.code"
                :readOnly="Boolean(company.id)"
                :error="errors.code"
            />
            <TextField
                class="w-75"
                id="name"
                :label="$t('company.property.name')"
                v-model="company.name"
                :error="errors.name"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TextField from "../Form/TextField";
import Loader from "../../components/Page/Loader";
import {useToast} from "vue-toastification";
import HeaderTitle from "../Page/Content/HeaderTitle";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import ConfirmationModal from "@/components/Utils/ConfirmationModal";
import CompanyService from "@/services/company.service";

export default {
  name: "CompanyForm",
  components: {Breadcrumbs, HeaderTitle, TextField, Loader, ConfirmationModal},
  setup() {
    const toast = useToast();

    return {toast};
  },
  props: {
    companyCode: String,
    title: String,
    breadcrumbsItems: Object
  },
  data() {
    return {
      isLoaded: false,
      isSubmitting: false,
      isDeleting: false,
      company: {},
      errors: {},
      companyOptions: []
    }
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      this.errors = {};
      await CompanyService.updateOrCreateCompany(this.company)
          .then((response) => {
            this.toast.success(this.$i18n.t('common.successSubmission'));
            this.company = response.data;
            if (!this.companyCode) {
              this.$router.push('/company/' + response.data.code);
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              this.toast.error(this.$i18n.t(error.response.data['hydra:title'] ?? error.response.data['message']));
              for (const violation of error.response.data.violations) {
                this.errors[violation.propertyPath] = violation.message;
              }
            } else {
              this.toast.error(this.$i18n.t('common.errorOccurred'));
            }
          });
      this.isSubmitting = false;
    },
    async deleteCompany() {
      this.isDeleting = true;
      await CompanyService.removeCompany(this.company.code)
          .then(() => {
            this.toast.success(this.$i18n.t('company.alert.message.deletion_success'));
            this.$router.push('/companies');
          })
          .catch((error) => {
            console.log(error);
            this.toast.error(this.$i18n.t('common.errorOccurred'));
          });
      this.isDeleting = false;
    }
  },
  computed: {
    computedTitle() {
      let title = this.title;
      if (this.company.name) {
        title += ' ' + this.company.name;
      }

      return title;
    }
  },
  async beforeMount() {
    this.isLoaded = true;
    if (this.companyCode) {
      await CompanyService.getCompany(this.companyCode)
          .then((response) => {
            this.company = response.data;
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    }

    this.isLoaded = false;
  }
}
</script>
