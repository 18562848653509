<template>
  <Breadcrumbs :items="breadcrumbsItems"/>
  <Loader v-if="isLoading || isSubmitting"/>
  <div v-if="!isLoading && !isSubmitting" class="container-fluid">
    <form id="connection-form" class="mt-3" @submit.prevent="onSubmit()">
      <HeaderTitle :title="title">
        <template v-slot:button-area>
          <button v-if="connection.id && isGranted('ROLE_CLIENT_ADMIN')" type="button" class="m-1 btn btn-danger"
                  :disabled="isDeleting" data-bs-toggle="modal"
                  data-bs-target="#confirm-deletion">
            <i class="bi bi-trash2" aria-hidden="true"/>
            {{ $t('common.delete') }}
          </button>
          <ConfirmationModal v-if="isGranted('ROLE_CLIENT_ADMIN')" modal-id="confirm-deletion" title="Confirm deletion"
                             :action="deleteConnection">
            <template v-slot:content>
              {{ $t('common.please_confirm') }}
            </template>
          </ConfirmationModal>
          <button type="submit" class="btn btn-success" :disabled="isSubmitting">
            <i class="bi bi-check-lg"/>
            {{ $t('common.save') }}
          </button>
        </template>
      </HeaderTitle>
      <div class="container">
        <TextField
            class="w-75"
            id="label"
            :label="$t('connection.edit.label')"
            v-model="connection.label"
            :required="true"
            :error="errors.label"
        />
        <SelectField
            v-if="companyOptions.length > 0 && isGranted('ROLE_ADMIN')"
            id="company"
            :label="$t('job.jobInstance.property.company_name')"
            v-model="connection.companyCode"
            :error="errors.company"
            :options="companyOptions"
            class="w-75 mb-3"
        />
        <TextField
            class="w-75"
            id="isApp"
            :label="$t('connection.edit.akeneo.url')"
            v-model="connection.url"
            :read-only="isApp"
            :required="true"
            :error="errors.url"
        />
        <p v-if="isApp">{{$t('connection.edit.akeneo.app.text')}}</p>
        <PasswordField
            v-if="!isApp"
            id="pim_clientId"
            class="w-75"
            :label="$t('connection.edit.akeneo.clientId')"
            v-model="connection.clientId"
            :required="!connection.clientIdSet"
            :error="errors.clientId"
        />
        <PasswordField
            v-if="!isApp"
            id="pim_secret"
            class="w-75"
            :label="$t('connection.edit.akeneo.secret')"
            v-model="connection.secret"
            :required="!connection.secretSet"
            :error="errors.secret"
        />
        <PasswordField
            v-if="!isApp"
            id="pim_user"
            class="w-75"
            :label="$t('connection.edit.akeneo.user')"
            v-model="connection.user"
            :required="!connection.userSet"
            :error="errors.user"
        />
        <PasswordField
            v-if="!isApp"
            id="pim_password"
            class="w-75"
            :label="$t('connection.edit.akeneo.password')"
            v-model="connection.password"
            :required="!connection.passwordSet"
            :error="errors.password"
        />
        <div v-if="errors.connectionTest" class="invalid-feedback  d-block">
          {{ errors.connectionTest }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from "@/components/Page/Loader";
import {useToast} from "vue-toastification";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import ConfirmationModal from "@/components/Utils/ConfirmationModal";
import CompanyService from "@/services/company.service";
import securityMixin from "@/mixins/securityMixin";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField";
import SelectField from "@/components/Form/SelectField";
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import UserService from "@/services/user.service";
import PasswordField from "@/components/Form/PasswordField";

export default {
  name: "AkeneoConnectionEditForm",
  components: {Breadcrumbs, HeaderTitle, SelectField, TextField, PasswordField, Loader, ConfirmationModal},
  mixins: [securityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionId: String,
    title: String,
    type: String,
    breadcrumbsItems: Object
  },
  data () {
    return {
      isLoading: false,
      isSubmitting: false,
      isDeleting: false,
      connection: {},
      errors: {},
      companyOptions: []
    }
  },
  computed: {
    isApp() {
      return this.connection.app === true;
    }
  },
  methods: {
    async onSubmit () {
      this.isSubmitting = true;
      this.errors       = {};
      await ConnectionService.updateOrCreateConnection(this.type, this.connection)
          .then((response) => {
            this.toast.success(this.$i18n.t('common.successSubmission'));
            this.connection = response.data;
            if (this.connection.company) {
              this.connection.companyCode = this.connection.company.code;
            }

            this.$router.push('/connections');
          })
          .catch((error) => {
            this.isSubmitting = false;
            if (error.response && error.response.data) {
              this.toast.error(this.$i18n.t(error.response.data['hydra:title'] ?? error.response.data['message']));
              for (const violation of error.response.data.violations) {
                this.errors[violation.propertyPath] = violation.message;
              }
            } else {
              this.toast.error(this.$i18n.t('common.errorOccurred'));
            }
          });
      this.isSubmitting = false;
    },
    async deleteConnection () {
      this.isDeleting = true;
      await ConnectionService.removeConnection(this.type, this.connection.id)
          .then(() => {
            this.toast.success(this.$i18n.t('connection.alert.message.deletion_success'));
            this.$router.push('/connections');
          })
          .catch((error) => {
            console.log(error);
            this.toast.error(this.$i18n.t('common.errorOccurred'));
          });
      this.isDeleting = false;
    }
  },
  async beforeMount () {

    this.isLoading = true;
    await UserService.getUserFromId(this.currentUser.id)
        .then((response) => {
          this.connection.companyCode = response.data.company.code;
        })
        .catch(error => console.log(error));

    if (this.connectionId) {
      await ConnectionService.getConnectionFromId(this.type, this.connectionId)
          .then((response) => {
            this.connection = response.data;
            if (this.connection.company) {
              this.connection.companyCode = response.data.company.code;
            }
          })
          // TODO Handle error better than this.
          .catch((error) => {
            console.log(error)
            this.toast.error(this.$i18n.t('common.errorOccurred'));
            this.$router.push('/connections');
          });
    }

    await CompanyService.getCompaniesList()
        .then((response) => {
          for (let company of response.data['hydra:member']) {
            this.companyOptions.push({
              id: company.code,
              text: company.name
            });
          }
        })
        // TODO Handle error better than this.
        .catch(error => console.log(error));

    this.isLoading = false;
  }
}
</script>

<style scoped>

</style>
