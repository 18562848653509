<template>
  <div class="card">
    <div class="generic-connection-request">
      <div class="card-header" @click="openModal">
        <h5 class="card-title">{{ title }}</h5>
      </div>
    </div>
    <div class="modal fade"
         ref="requestModal"
         tabindex="-1"
         aria-hidden="true"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="requestModalLabel">
              {{ title }}
            </h5>
          </div>
          <div class="modal-body">
            <div id="fields" v-if="!showResult && !isLoading">
              <slot name="request-fields"></slot>
            </div>
            <Loader v-if="isLoading"/>
            <div id="result" v-if="showResult">
              <h5>{{ $t('connection.data.result') }}</h5>
              <JsonField
                  id="result"
                  :read-only="true"
                  :disabled="true"
                  :rows=3
                  :modes="['code', 'tree']"
                  v-model="this.result"
                  :show-btns="true"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button @click="hideModal" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-x-lg" aria-hidden="true"/>
              {{ $t('connection.data.close') }}
            </button>
            <button v-if="!showResult" @click="clickRequestSend" :disabled="isLoading"
                    class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-download" aria-hidden="true"/>
              {{ $t('connection.data.send_request') }}
            </button>
            <button v-if="showResult" @click="showResult = false" class="mt-3 btn btn-sm btn-secondary">
              <i class="bi bi-backspace" aria-hidden="true"/>
              {{ $t('connection.data.send_another_request') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import JsonField from "@/components/Form/JsonField.vue";
import {useToast} from "vue-toastification";
import Loader from "@/components/Page/Loader.vue";

export default {
  name: "GenericDataRequest",
  components: {
    Loader,
    JsonField,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    id: String,
    title: String,
    connection: Object,
    sendRequestFunction: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      modal: null,
      showResult: false,
      isLoading: false,
      search: "{}",
      result: "{}",
    }
  },
  methods: {
    openModal () {
      this.modal.show();
      this.isLoading  = false;
      this.showResult = false;
    },
    hideModal () {
      this.modal.hide();
    },
    clickRequestSend () {
      this.isLoading = true;
      try {
        this.sendRequestFunction().then((result) => {
          this.showResult = true;
          this.result     = JSON.stringify(result.data);
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            this.toast.error(error.response.data.message);
          } else {
            this.toast.error(error.message);
          }
        }).finally(() => {
          this.isLoading = false
        });
      } catch (e) {
        this.isLoading = false;
      }
    }
  },
  mounted () {
    this.modal = new Modal(this.$refs.requestModal)
  },
}
</script>

<style lang="scss">

.generic-connection-request {
  cursor: pointer;

  .card-header {
    min-height: 120px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    background-color: lightgray;
  }
}

</style>
