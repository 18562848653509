<template>
  <div class="mb-3">
    <label
        v-if="label"
        class="form-label">
      {{ label }}
    </label>

    <div class="container ms-0">
      <div class="row col-9">
        <div class="col-lg-4 col-md-6" v-for="(rule, index) in rules" :key="confInputKey + index">
          <BynderRuleField
              class="mt-3"
              :id="'rule-' + index"
              :rule=rule
              v-on:rule-save="saveRule($event, index)"
              @rule-remove="removeRule(index)"
              :errors="this.getRuleErrors(index)"
          />
        </div>
      </div>
    </div>
    <button @click="addRule" class="m-3 btn btn-primary">
      <i class="bi bi-plus" aria-hidden="true"/>
      {{ $t('form.bynder.rules.add_rule') }}
    </button>
  </div>
</template>

<script>

import BynderRuleField from "@/components/Job/JobInstance/Configuration/Bynder/Rules/BynderRuleField";

export default {
  name: "BynderRuleFields",
  components: { BynderRuleField },
  props: {
    id: String,
    label: String,
    modelValue: Array,
    errors: Object,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confInputKey: Math.random(),
      rules: this.modelValue
    }
  },
  methods: {
    getRuleErrors (index) {
      if (this.errors && this.errors[index]) {
        return this.errors[index];
      }
      return null;
    },
    addRule (e) {
      if (!this.rules) {
        this.rules = [];
      }

      this.rules.push({
        type: "",
        priority: 0,
        conditions: [],
      });

      e.preventDefault();
    },
    saveRule (rule, index) {
      this.rules[index] = rule;
      this.updateValue();
    },
    removeRule (index) {
      this.rules.splice(index, 1);
      this.updateValue();
    },
    updateValue () {
      this.$emit(`update:modelValue`, this.rules);
      this.confInputKey = Math.random()
    }
  },
}
</script>
