<template>
  <div class="card connection-summary-card">
    <div class="card-body">
      <slot name="card-content"></slot>
      <div v-if="this.errors[connection.id]" class="card-footer invalid-feedback d-block">
        {{ this.errors[connection.id] }}
      </div>
      <div v-if="this.success[connection.id]" class="card-footer success d-block">
        {{ this.success[connection.id] }}
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-success float-end ms-2"
              @click="testConnection(connection)"
              :disabled="connection.isTesting"
      >
        <i class="bi bi-magic"/>
        {{ $t('connection.test') }}
      </button>
      <button v-if="hasDataRoute && isGranted('ROLE_ADMIN')" type="button" class="btn btn-secondary float-end ms-2"
              @click="$router.push('/connections/data/' + connectionType + '/' + connection.id)"
      >
        <i class="bi bi-filetype-json"/>
        {{ $t('connection.data') }}
      </button>
      <button type="button" class="btn btn-primary float-end"
              @click="$router.push('/connections/edit/' + connectionType + '/' + connection.id)"
      >
        <i class="bi bi-pencil"/>
        {{ $t('connection.edit') }}
      </button>
    </div>
  </div>
</template>

<script>
import ConnectionService from "@/services/connection.service";
import {useToast} from "vue-toastification";
import SecurityMixin from "@/mixins/securityMixin";

export default {
  name: "GenericConnectionSummary",
  mixins: [SecurityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionType: String,
    connection: Object,
    hasDataRoute: Boolean,
  },
  data () {
    return {
      errors: {},
      success: {},
    }
  },
  methods: {
    testConnection (connection) {
      connection.isTesting = true;
      ConnectionService.testConnection(this.connectionType, connection.id).then(() => {
        connection.isTesting = false;

        this.success[connection.id] = this.$i18n.t('connection.test.success_message');
        setTimeout(() => {
          this.success[connection.id] = null
        }, 3000);

        this.toast.success(this.$i18n.t('connection.test.success_message'));
      }).catch((error) => {
        connection.isTesting = false;

        if (error.response && error.response.data) {
          this.errors[connection.id] = this.$i18n.t(error.response.data['hydra:title'] ?? error.response.data['message']);
          setTimeout(() => {
            this.errors[connection.id] = null
          }, 3000);
        }

        this.toast.error(this.$i18n.t('connection.test.error_message'));
      })
    }
  }
}
</script>

<style lang="scss">
.connection-summary-card {
  min-height: 230px;
}
</style>
