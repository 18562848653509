<template>
  <div class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('connection.plural')">
      <template v-slot:button-area>
        <router-link class="m-1 btn btn-primary" to="/connections/create">
          <i class="bi bi-plus-lg"/>
          {{ $t('connection.create') }}
        </router-link>
      </template>
    </HeaderTitle>

    <ConnectionSummaries
        class="mt-3"
        :title="$t('connection.summary.akeneo.title')"
        connectionType="akeneo"
    />
    <ConnectionSummaries
        class="mt-3"
        :title="$t('connection.summary.bynder.title')"
        connectionType="bynder"
    />
    <ConnectionSummaries
        class="mt-3"
        :title="$t('connection.summary.shopify.title')"
        connectionType="shopify"
    />

  </div>
</template>

<script>
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import ConnectionSummaries from "@/views/Connection/Summary/ConnectionSummaries.vue";
import {useToast} from "vue-toastification";

export default {
  name: "ConnectionIndex",
  components: {
    ConnectionSummaries,
    Breadcrumbs,
    HeaderTitle,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  computed: {
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'connection', label: this.$i18n.t('connection.plural'), link: '/connections'},
      ]
    },
  },
  async beforeMount () {
    this.isLoading = false;
    if(this.$route.query.sucess_message !== null) {
      this.toast.success(this.$i18n.t(this.$route.query.sucess_message));
    }
  }
}
</script>

<style lang="scss">

</style>
