<template>
  <div class="mt-3">
    <h5>{{ $t('job.jobInstance.bynderAkeneo.config.bynder_configuration.title') }}</h5>
    <ConnectionSelectField
        class="w-75 mb-3"
        id="bynderConnectionFilter"
        type="bynder"
        :label="$t('job.jobInstance.bynderAkeneo.config.bynder_connection')"
        :onUpdate="(v) => {this.bynderConnection = v}"
        :baseValue="this.bynderConnection"
        :company-code="this.jobInstance.companyCode"
        :error="this.getPropertyErrors(['check_bynder_config', 'bynder_connection'])"
    />
  </div>
  <h5>{{ $t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.title') }}</h5>
  <ConnectionSelectField
      class="w-75 mb-3"
      id="akeneoConnectionFilter"
      type="akeneo"
      :label="$t('job.jobInstance.bynderAkeneo.config.akeneo_connection')"
      :onUpdate="(v) => {this.akeneoConnection = v}"
      :baseValue="this.akeneoConnection"
      :company-code="this.jobInstance.companyCode"
      :error="this.getPropertyErrors(['check_akeneo_config', 'akeneo_connection'])"
  />
  <h5>{{ $t('job.jobInstance.bynderAkeneo.config.delete_mappings.title') }}</h5>
  <p class="w-75">{{ $t('job.jobInstance.bynderAkeneo.config.delete_mappings.description') }}</p>
  <DeleteMappingFields
      id="deleteMappings"
      v-model="deleteMappings"
      :errors="this.getPropertyErrors(['bynder_to_akeneo_delete_assets', 'delete_akeneo_assets'])"
  />
</template>

<script>
import {useToast} from "vue-toastification";
import jobConfigurationMixin from "@/mixins/jobConfigurationMixin";
import ConnectionSelectField from "@/views/Connection/ConnectionSelectField";
import DeleteMappingFields from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/DeleteMappingFields.vue";

export default {
  name: "DeleteBynderAkeneoConfigurationForm",
  mixins: [jobConfigurationMixin],
  components: {
    DeleteMappingFields,
    ConnectionSelectField,
  },
  props: {
    modelValue: Object,
    errors: Object,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      jobInstance: this.modelValue,
      configuration: JSON.parse(this.modelValue.configuration),
    }
  },
  computed: {
    bynderConnection: {
      get () {
        return this.getJobConfiguration(["bynder_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["bynder_connection"], v)
      },
    },
    akeneoConnection: {
      get () {
        return this.getJobConfiguration(["akeneo_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["akeneo_connection"], v)
      },
    },
    deleteMappings: {
      get () {
        return this.getJobConfiguration(["delete_akeneo_assets"], []);
      },
      set (v) {
        this.setJobConfiguration(["delete_akeneo_assets"], v)
      },
    },
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>
