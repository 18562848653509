<template>
  <UserForm
      v-if="currentUser"
      :user-id="currentUser.id"
      :title="$t('user.view.userProfile')"
      :breadcrumbs-items="breadcrumbs"
  />
</template>

<script>
import {mapState} from 'vuex'
import UserForm from "../../components/User/UserForm";

export default {
  name: 'Profile',
  components: {UserForm},
  computed: {
    ...mapState({
      currentUser: state => state.auth.user
    }),
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'current', label: this.$t('user.view.userProfile'), isCurrent: 'true'},
      ]
    }
  },
};
</script>
