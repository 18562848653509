import authHeader from './Auth/auth-header';
import apiClient from "./api-client";

class ShopifyAppService {
    getAuthorizationUrl (shopUrl) {
        return apiClient().post('/api/shopify_app_accesses', {shopUrl: shopUrl});
    }

    callback(state, data) {
        return apiClient().post('/api/shopify_app_accesses/' + state + '/generate', data, {headers: authHeader()});
    }
}

export default new ShopifyAppService();
