<template>
  <div class="form-floating mb-3">
    <input
        type="number"
        class="form-control"
        :class="error ? 'is-invalid' : ''"
        :id="id"
        ref="numberInput"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :readonly="readOnly"
        data-bs-toggle="tooltip"
        :data-bs-placement="tooltipPlacement"
        :title="tooltip"
    />
    <label
        :for="id"
        class="form-label">
      {{ label }}
    </label>
    <div v-if="error" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import {Tooltip} from "bootstrap";

export default {
  name: "NumberField",
  props: {
    id: String,
    label: String,
    modelValue: Number,
    tooltip: String,
    error: String,
    readOnly: {
      type: Boolean,
      default: false
    },
    tooltipPlacement: {
      type: String,
      default: "right"
    }
  },
  methods: {
  },
  mounted () {
    const inputElement = this.$refs.numberInput;
    new Tooltip(inputElement);
  },
}
</script>
