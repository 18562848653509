<template>
  <Loader v-if="isLoading"/>
</template>

<script>

import Loader from "@/components/Page/Loader"
import ShopifyAppService from "@/services/shopify-app.service";
import {useToast} from "vue-toastification";

export default {
  name: "ShopifyAppActivateView",
  components: {Loader},
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      isLoading: true,
      isActivating: false,
    }
  },
  methods: {
    getShopUrl () {
      return this.$route.query.shop;
    },
    activate () {
      this.isActivating = true;

      ShopifyAppService.getAuthorizationUrl(this.getShopUrl())
        .then((response) => {
          if (response.data.authorizationUrl) {
            window.location.href = encodeURI(response.data.authorizationUrl);
          } else {
            this.toast.error(this.$i18n.t('common.errorOccurred'));
          }
        })
        .catch(error => {
          console.log(error);
          this.toast.error(this.$i18n.t('common.errorOccurred'));
          this.isActivating = false;
        });
    },
  },
  beforeMount () {
    this.isLoading = false;
    this.activate();
  },
}
</script>
