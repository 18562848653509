<template>
  <label class="text-secondary ms-2">{{ title }}</label>
  <Datepicker v-model="range" range @update:modelValue="handleChange" utc text-input/>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "DateTimeFilter",
  components: {
    Datepicker,
  },
  data() {
    return {
      range: []
    }
  },
  props: {
    title: String,
    code: String,
    options: Object,
    onUpdate: Function,
    baseValue: Object
  },
  methods: {
    handleChange(range) {
      let filterRange = null;
      if (null !== range) {
        filterRange = {};
        if (range[0]) {
          filterRange.after = range[0];
        }
        if (range[1]) {
          filterRange.before = range[1];
        }
      }

      this.$emit(`update:modelValue`, filterRange);

      if (this.onUpdate) {
        this.onUpdate(filterRange);
      }
    }
  },
  beforeMount() {
    if (this.baseValue) {
      this.range.push(this.baseValue.after ?? null);
      this.range.push(this.baseValue.before ?? null);
    }
  }
};
</script>

<style scoped lang="scss">
label {
  font-size: 0.9rem !important
}
</style>
