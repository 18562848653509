<template>
  <GenericConnectionSummary
    :connection="connection"
    :hasDataRoute="hasDataRoute"
    :connection-type="connectionType"
  >
    <template v-slot:card-content>
      <h5 class="card-title">{{ connection.label }}</h5>
      <p class="card-text">{{ $t('connection.summary.bynder.domain', {domain: connection.domain}) }}</p>
      <p class="card-text">{{ $t('connection.summary.company', {company: connection.company.name}) }}</p>
      <p v-if="connection.app" class="card-text">{{ $t('connection.summary.bynder.is_app') }}</p>
    </template>
  </GenericConnectionSummary>
</template>
<script>

import GenericConnectionSummary from "@/views/Connection/Summary/GenericConnectionSummary.vue";

export default {
  name: "AkeneoConnectionSummary",
  components: {GenericConnectionSummary},
  props: {
    connectionType: String,
    connection: Object,
    hasDataRoute: Boolean,
  },
  data () {
    return {
      errors: {},
      success: {},
    }
  },
}
</script>
