<template>
  <div class="mb-3">
    <label
        class="form-label">
      {{ label }}
    </label>
    <Vue3JsonEditor
        v-model="json"
        :expandedOnStart="true"
        @json-change="onJsonChange"
        @has-error="onError"
        :mode="mode"
        :modes="modes"
    />
    <div v-if="errors || jsonError" class="invalid-feedback d-inline-block">
      <span v-html="prettyError"></span>
      <span> {{ jsonError }} </span>
    </div>
  </div>
</template>

<script>
import {Vue3JsonEditor} from 'vue3-json-editor'

export default {
  name: "JsonField",
  components: {
    Vue3JsonEditor
  },
  props: {
    id: String,
    label: String,
    modelValue: String,
    errors: Object,
    readOnly: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 15
    },
    modes: {
      type: Array,
      default: () => ['tree', 'code']
    }
  },
  data () {
    return {
      jsonError: null,
      jsonSuccess: null,
      json: this.modelValue ? JSON.parse(this.modelValue) : JSON.parse('{}'),
      mode: this.modes[0]
    }
  },
  computed: {
    prettyError () {
      if (this.errors) {
        let errorMessages = [];
        errorMessages = this.getErrorMessages(this.errors, []);
        if (errorMessages.length > 0) {
          let errorListHtml = '<ul><li>'
          errorListHtml += errorMessages.join("<li></li>");
          errorListHtml += '</li></ul>'

          return errorListHtml;
        }
      }

      return "";
    }
  },
  methods: {
    onJsonChange (value) {
      this.jsonError = null;
      this.$emit(`update:modelValue`, JSON.stringify(value));
    },
    onError (value) {
      this.jsonError = value;
    },
    getErrorMessages (errorsObj, errorMessages, path = []) {
      for (const key in errorsObj) {
        const newPath = path.concat(key);
        if (typeof errorsObj[key] === "object") {
          this.getErrorMessages(errorsObj[key], errorMessages, newPath);
        } else {
          newPath.shift();
          const messageString = newPath.join(' - ') + ': ' + errorsObj[key];

          errorMessages.push(messageString);
        }
      }
      return errorMessages;
    }
  }
}
</script>

<style lang="scss">
.ace-jsoneditor {
  min-height: 500px !important;
}
</style>
