<template>
  <div class="form-floating mb-3">
    <Datepicker
        v-model="localModelValue"
        :readonly="readOnly"
        class="form-control w-75"
        style="height: 75px"
        utc
    />
    <label
        :for="id"
        class="form-label">
      {{ label }}
    </label>
    <div v-if="error" class="invalid-feedback d-inline-block">
      {{ error }}
    </div>
  </div>
</template>

<script>

import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";

export default {
  name: "DateTimeField",
  components: {
    Datepicker,
  },
  props: {
    id: String,
    label: String,
    modelValue: String,
    format: String,
    error: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        const formattedDate = moment.utc(newValue).format(this.format);
        this.$emit('update:modelValue', formattedDate);
      },
    },
  }
}
</script>
