<template>
  <div class="row">
    <div class="text-center text-black login-view">
      <div class="align-items-center h-50 login-header">
        <img :src="getImage('platform-logo.png')" class="w-150">
        <h1 class="title">Connect Platform</h1>
        <span>By Dataggo ©</span>
      </div>

      <div class="m-auto">
        <form @submit.prevent="handleLogin" class="col-lg-4 col-sm-8 m-auto">
          <div class="form-floating mb-4">
            <input v-model="user.username" type="text" required class="form-control form-control-lg" id="username"
                   placeholder="User name">
            <label for="username">
              {{ $t('security.identifier') }}
            </label>
          </div>
          <div class="form-floating mb-4">
            <input v-model="user.password" type="password" required class="form-control form-control-lg" id="password"
                   placeholder="Password">
            <label for="password">
              {{ $t('security.password') }}
            </label>
          </div>
          <div class="d-grid gap-2 mb-2">
            <button class="btn btn-primary" type="submit" :disabled="loading">
              {{ $t('security.login') }}
            </button>
          </div>
          <p class="text-lg-start small mb-5 pb-lg-2 mx-auto">
            <router-link class="nav-link" to="/forget-password">
              {{ $t('security.forgotPwdQuestion') }}
            </router-link>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {useToast} from "vue-toastification";
import UserService from "../../services/user.service";
import appStyleMixin from "@/mixins/appStyleMixin";

export default {
  name: "Login",
  mixins: [appStyleMixin],
  components: {},
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      loading: false,
      user: {}
    };
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.status.loggedIn,
      loggedUser: state => state.auth.user,
      currentUser: state => state.auth.user
    }),
  },
  created () {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      logOut: 'auth/logout',
    }),
    handleLogin () {
      this.loading = true;
      const i18n   = this.$i18n;
      this.login(this.user).then(
          () => {
            UserService.getUserFromId(this.currentUser.id).then((response) => {
              let user = response.data;
              if (user.preferredLocale) {
                i18n.locale = user.preferredLocale;
                localStorage.setItem('locale', user.preferredLocale)
              }
            })

            let redirectPath  = sessionStorage.getItem('redirectPath') || '/';
            let redirectQuery = JSON.parse(sessionStorage.getItem('redirectQuery') || '{}');

            this.$router.replace({path: redirectPath, query: redirectQuery});
            sessionStorage.removeItem('redirectPath');
            sessionStorage.removeItem('redirectQuery');
          },
          (error) => {
            let message = error.response.data.message ? error.response.data.message : error.message;
            console.log(error);
            this.toast.error(this.$i18n.t(message));
            this.loading = false;
          }
      );
    },
  },
  beforeMount () {
    if (navigator.language) {
      this.$i18n.locale = navigator.language.split('-')[0];
    }
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Fira+Code');

.title {
  font-family: 'Fira Code', serif;
  font-size: 3.5rem;
}

</style>
