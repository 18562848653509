<template>
  <tr v-if="visible">
    <td>
      <input :readonly="Boolean(id)" class="form-control" type="text" v-model="code"/>
    </td>
    <td>
      <input class="form-control" type="text" v-model="value" @input="unlockSaveButton"/>
    </td>
    <td>
      <button :disabled="isUpdating || lockSaveButton" class="btn btn-sm btn-success me-2"
              @click.prevent="createOrUpdate">
        <i class="bi bi-check"/>
      </button>
      <button :disabled="isUpdating" class="btn btn-sm btn-danger" @click.prevent="remove">
        <i class="bi bi-trash"/>
      </button>
    </td>
  </tr>
</template>

<script>
import JobService from "../../../../services/job.service";
import {useToast} from "vue-toastification";

export default {
  name: "SecuredVariableFormRow",
  props: {
    variable: Object,
    jobInstance: Object
  },
  setup() {
    const toast = useToast();

    return {toast};
  },
  data() {
    return {
      id: this.variable.id,
      code: this.variable.code,
      value: this.variable.id ? '********' : '',
      isUpdating: false,
      lockSaveButton: this.variable.id,
      visible: true
    }
  },
  methods: {
    unlockSaveButton() {
      this.lockSaveButton = false;
    },
    createOrUpdate() {
      this.isUpdating = true;
      let variable = {};

      variable.id = this.variable.id
      variable.code = this.code;
      variable.value = this.value;
      variable.jobInstance = this.jobInstance.code;

      JobService.createOrUpdateJobVariable(variable).then(
          (response) => {
            this.toast.success(this.$i18n.t('common.successSubmission'));
            this.id = response.data.id;
            this.lockSaveButton = true;
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error))
          .finally(() => {
            this.isUpdating = false;
          });
    },
    remove() {
      this.isUpdating = true;

      if (this.id) {
        JobService.deleteJobVariable(this.id).then(
            (response) => {
              this.toast.success(this.$i18n.t('common.successSubmission'));
              this.id = response.data.id;
            })
            // TODO Handle error better than this.
            .catch(error => console.log(error))
            .finally(() => {
              this.isUpdating = false;
            });
      }

      this.visible = false;
    }
  }
}
</script>

<style scoped lang="scss">
table {
  tbody {
    td:last-child {
      width: 100px;
    }
  }
}
</style>
