const adminRole = 'ROLE_ADMIN';
const clientAdminRole = 'ROLE_CLIENT_ADMIN';
const userRole = 'ROLE_USER';

const roleHierarchy = {
  [adminRole]: [clientAdminRole, userRole],
  [clientAdminRole]: [userRole],
}

class SecurityService {
  isGranted(userRoles, accessRole) {
    for (const [role, grantedRoles] of Object.entries(roleHierarchy)) {
      if (userRoles.includes(role) && grantedRoles.includes(accessRole)) {
        return true;
      }
    }

    return userRoles.includes(accessRole);
  }
}

export default new SecurityService();
