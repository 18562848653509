<template>
  <div class="offcanvas offcanvas-start w-25" tabindex="-1" id="filters-offcanvas" data-bs-backdrop="true">
    <div class="offcanvas-header">
      <h4 class="offcanvas-title d-none d-sm-block">
        {{ $t('datagrid.title.filters') }}
      </h4>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
    </div>
    <div class="offcanvas-body px-0">
      <div v-for="filterConf in filtersConf" :key="filterConf.code" class="p-2">
        <Filter :conf="filterConf" :on-filter-update="onFilterUpdate" :value="values[filterConf.code]" :grid-name="gridName"/>
      </div>
    </div>
    <div style="max-height: 60px; min-height: 60px" class="d-flex order-2 w-100">
      <button type="button" class="btn btn-success w-100 rounded-0" data-bs-dismiss="offcanvas" @click.prevent="filter()">
        Update
      </button>
    </div>
  </div>
  <button class="btn btn-light" data-bs-toggle="offcanvas" data-bs-target="#filters-offcanvas" role="button">
    <i class="bi bi-filter"/>
    {{ $t('datagrid.action.filter') }}
    <span class="badge bg-secondary" v-if="numberOfUsedFilters > 0">{{ numberOfUsedFilters }}</span>
  </button>
</template>

<script>
import Filter from "@/components/Utils/Datagrid/Filter";
export default {
  name: "Filters",
  components: {Filter},
  data() {
    return {
      values: this.baseValues || {}
    }
  },
  props: {
    gridName: String,
    baseValues: {
      type: Object,
      default: null
    },
    filtersConf: Array,
    onFilter: Function
  },
  methods: {
    onFilterUpdate(filterCode, value) {
      if (!((Array.isArray(value) && value.length === 0) || '' === value || null === value)) {
        this.values[filterCode] = value;

        return;
      }
      if (filterCode in this.values) {
          delete this.values[filterCode];
      }
    },
    filter() {
      this.onFilter(this.values);
    }
  },
  computed: {
    numberOfUsedFilters() {
      return Object.keys(this.values).length;
    }
  }
}
</script>
