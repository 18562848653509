<template>
  <JobInstanceForm :jobInstanceCode="$route.params.code"/>
</template>

<script>
import JobInstanceForm from "@/components/Job/JobInstance/JobInstanceForm";

export default {
  name: "JobInstanceEdit",
  components: {JobInstanceForm}
}
</script>
