<template>
  <Breadcrumbs :items="breadcrumbsItems"/>
  <Loader v-if="isLoading || isSubmitting"/>
  <div v-if="!isLoading && !isSubmitting" class="container-fluid">
    <HeaderTitle :title="title">
      <template v-slot:under-title-area>
        <span v-if="connection.id" class="text-muted">{{ connection.label }}</span>
      </template>
    </HeaderTitle>
    <div class="container ms-0">
      <div class="row col-12">
        <slot name="request-components"></slot>
      </div>
    </div>
  </div>
</template>


<script>
import {useToast} from "vue-toastification";
import Loader from "@/components/Page/Loader.vue";
import Breadcrumbs from "@/components/Page/Breadcrumbs.vue";
import securityMixin from "@/mixins/securityMixin";
import ConnectionService from "@/services/connection.service";
import UserService from "@/services/user.service";
import HeaderTitle from "@/components/Page/Content/HeaderTitle.vue";

export default {
  name: "GenericConnectionData",
  components: {
    Breadcrumbs,
    HeaderTitle,
    Loader,
  },
  emits: ['update-connection'],
  mixins: [securityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionId: String,
    title: String,
    type: String,
    breadcrumbsItems: Object
  },
  data () {
    return {
      isLoading: false,
      isSubmitting: false,
      isDeleting: false,
      connection: {},
      errors: {},
      companyOptions: []
    }
  },
  async beforeMount () {
    this.isLoading = true;
    await UserService.getUserFromId(this.currentUser.id)
        .then((response) => {
          this.connection.companyCode = response.data.company.code;
        })
        .catch(error => console.log(error));

    if (this.connectionId) {
      await ConnectionService.getConnectionFromId(this.type, this.connectionId)
          .then((response) => {
            this.connection = response.data;
            if (this.connection.company) {
              this.connection.companyCode = response.data.company.code;
            }
            this.$emit('update-connection', this.connection);
          })
          // TODO Handle error better than this.
          .catch((error) => {
            console.log(error)
            this.toast.error(this.$i18n.t('common.errorOccurred'));
            this.$router.push('/connections');
          });
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>

</style>
