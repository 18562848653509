import apiClient from "@/services/api-client";

const AUTHENTICATION_ENDPOINT = '/authentication_token';

class AuthService {
    login(user) {
        return apiClient()
            .post(AUTHENTICATION_ENDPOINT, {
                username: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AuthService();
