<template>
  <div class="mb-3">
    <label
        v-if="label"
        class="form-label">
      {{ label }}
    </label>

    <div class="container ms-0">
      <div class="row col-9">
        <div class="" v-for="(mediaUrl, index) in mediaUrls" :key="confInputKey + index">
          <MediaUrlField
              class="mt-3"
              :id="'mapping-' + index"
              :mediaUrlData=mediaUrl
              @media-url-save="saveMediaUrl($event, index)"
              @media-url-remove="removeMediaUrl(index)"
              :error="this.getMediaUrlErrorMessage(index)"
          />
        </div>
      </div>
    </div>
    <button @click="addMedialUrl" class="m-3 btn btn-primary">
      <i class="bi bi-plus" aria-hidden="true"/>
      {{ $t('form.bynder_filters.add_media_url') }}
    </button>
  </div>
</template>

<script>

import MediaUrlField from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/MediaUrlField";

export default {
  name: "MediaUrlFields",
  components: {MediaUrlField},
  props: {
    id: String,
    label: String,
    modelValue: Array,
    errors: Object,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confInputKey: Math.random(),
      mediaUrls: this.modelValue
    }
  },
  methods: {
    getMediaUrlErrorMessage (index) {
      if (this.errors && this.errors[index]) {
        return this.errors[index];
      }
      return null;
    },
    addMedialUrl (e) {
      if (!this.mediaUrls) {
        this.mediaUrls = [];
      }

      this.mediaUrls.push({
        bynderMetadata: "",
        attribute: "",
      });

      e.preventDefault();
    },
    saveMediaUrl (mediaUrl, index) {
      this.mediaUrls[index] = mediaUrl;
      this.updateValue();
    },
    removeMediaUrl (index) {
      this.mediaUrls.splice(index, 1);
      this.updateValue();
      this.confInputKey = Math.random();
    },
    updateValue () {
      this.$emit(`update:modelValue`, this.mediaUrls);
    }
  },
}
</script>
