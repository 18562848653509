<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <p>
        <a href="https://api.akeneo.com/documentation/filter.html#filter-reference-entity-records" target="_blank">{{$t('connection.data.akeneo.details_link')}}</a>.
      </p>
      <TextField
          id="assetFamily"
          class="w-100"
          :label="$t('connection.data.akeneo.reference_entity')"
          v-model="referenceEntity"
      />
      <p>{{ $t('connection.data.search') }}</p>
      <JsonField
          class=""
          id="search"
          :rows=3
          :modes="['code']"
          v-model="search"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import JsonField from "@/components/Form/JsonField.vue";
import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField.vue";
import {useToast} from "vue-toastification";

export default {
  name: "AkeneoRefEntitySearchDataRequest",
  components: {
    TextField,
    GenericDataRequest,
    JsonField,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      search: '{}',
      referenceEntity: '',
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      if(this.referenceEntity === '') {
        this.toast.error(this.$t('connection.data.akeneo.invalid_reference_entity'));
      } else {
        return ConnectionService.getAkeneoData(this.connection.id, 'ref_entity_record_search', {
          search: this.search,
          referenceEntity: this.referenceEntity,
        })
      }
    }
  },
}
</script>

