<template>
  <div class="w-75 mb-3">
    <div class="form-floating d-inline-block secured-variable-input">
      <input
          type="text"
          class="form-control"
          :id="code"
          ref="textInput"
          v-model="value"
          @input="unlockSaveButton"
          @click.once="() => {this.value = ''}"
          :readonly="readOnly"
          data-bs-toggle="tooltip"
          :data-bs-placement="tooltipPlacement"
          :title="tooltip"
      />
      <label
          :for="code"
          class="form-label w-100">
        <div class="d-inline-block">{{ label }}</div>
        <div v-if="required" class="d-inline-block">*</div>
      </label>
    </div>
    <button :disabled="isUpdating || readOnly || lockSaveButton"
            class="btn btn-sm btn-success d-inline secured-variable-button"
            @click.prevent="createOrUpdate">
      <i class="bi bi-check"/>
    </button>
  </div>
</template>

<script>
import JobService from "../../../../services/job.service";
import {useToast} from "vue-toastification";
import {Tooltip} from "bootstrap";

export default {
  name: "SecuredVariableField",
  props: {
    label: String,
    variable: Object,
    jobInstance: Object,
    tooltip: String,
    required: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    tooltipPlacement: {
      type: String,
      default: "bottom"
    }
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      id: this.variable.id,
      code: this.variable.code,
      value: this.variable.id ? '********' : '',
      isUpdating: false,
      lockSaveButton: true,
      visible: true
    }
  },
  methods: {
    unlockSaveButton () {
      if (!this.readOnly) {
        this.lockSaveButton = false;
      }
    },
    createOrUpdate () {
      this.isUpdating = true;
      const variable  = {
        id: this.id,
        code: this.code,
        value: this.value,
        jobInstance: this.jobInstance.code,
      };

      JobService.createOrUpdateJobVariable(variable).then(
          (response) => {
            this.toast.success(this.$i18n.t('common.successSubmission'));
            this.id             = response.data.id;
            this.lockSaveButton = true;
          })
          // TODO Handle error better than this.
          .catch(error => {
            console.log(error);
            this.toast.error(this.$i18n.t('common.errorOccurred'));
          })
          .finally(() => {
            this.isUpdating = false;
          });
    },
  },
  mounted() {
    const inputElement = this.$refs.textInput;
    new Tooltip(inputElement);
  },
}
</script>

<style scoped lang="scss">
.secured-variable-input {
  width: calc(100% - 42px);
}

.secured-variable-button {
  width: 32px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
}
</style>
