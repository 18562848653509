<template>
  <div class="form-floating mb-3">
        <textarea
            class="form-control w-75 textarea-field"
            :class="error ? 'is-invalid' : ''"
            :id="id"
            :placeholder="label"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :readonly="readOnly"
            :rows="rows"
        >
        </textarea>
    <label
        :for="id"
        class="form-label">
      {{ label }}
    </label>
    <div v-if="error" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAreaField",
  props: {
    id: String,
    label: String,
    modelValue: String,
    error: String,
    readOnly: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 8
    }
  }
}
</script>

<style scoped lang="scss">
.textarea-field {
  height: 100% !important;
}
</style>
