<template>
  {{ val }}
</template>

<script>
export default {
  name: "DefaultFormatter",
  props: ['val']
}
</script>
