<template>
  <Loader v-if="isLoading"/>
  <div class="container-fluid" v-if="!isLoading">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('company.plural')">
      <template v-slot:button-area>
        <router-link class="m-1 btn btn-primary" to="/company/create">
          <i class="bi bi-plus-lg"/>
          {{ $t('company.create') }}
        </router-link>
      </template>
      <template v-slot:under-title-area>
        <p class="d-block text-muted">
          {{ totalCount }} {{ $t('common.results') }}
        </p>
      </template>
    </HeaderTitle>
    <div v-if="totalCount === 0" class="text-center mt-lg-5">
      {{ $t('company.no_company_create_yet') }}
    </div>
    <div v-else>
      <Pager :current-page="page" :on-page-selection="changePage" :total-count="totalCount"/>
      <CompanyGrid :items="companies"/>
      <Pager :current-page="page" :on-page-selection="changePage" :total-count="totalCount"/>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import Loader from "@/components/Page/Loader";
import Pager from "@/components/Utils/Pager";
import CompanyService from "@/services/company.service";
import CompanyGrid from "@/components/Company/CompanyGrid";

export default {
  name: "Companyindex",
  components: {CompanyGrid, Breadcrumbs, HeaderTitle, Loader, Pager},
  data() {
    return {
      isLoading: true,
      totalCount: 0,
      companies: [],
      page: 1
    }
  },
  methods: {
    async changePage(page) {
      this.isGridLoading = true;
      this.page = page;
      await CompanyService.getCompanies(page)
          .then((response) => {
            this.companies = response.data['hydra:member'];
          })
          .catch(error => console.log(error));

      this.isGridLoading = false;
    },
  },
  computed: {
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'configuration', label: this.$i18n.t('common.configuration'), link: '/configuration'},
        {name: 'compaies', label: this.$i18n.t('company.plural'), isCurrent: true},
      ]
    }
  },
  async beforeMount() {
    await CompanyService.getCompanies()
        .then((response) => {
          this.companies = response.data['hydra:member'];
          this.totalCount = response.data['hydra:totalItems'];
        })
        .catch((error) => {
          console.log(error);
        })
    this.isLoading = false;
  }
}
</script>

<style scoped>

</style>
