class LocaleService {
    getAvailableLocales() {
        return ['en', 'fr'];
    }

    getAvailableLocalesSelectOptions() {
        let options = [];
        for (let locale of this.getAvailableLocales()) {
            options.push({
                id: locale,
                text: 'common.language.' + locale,
            });
        }

        return options;
    }
}

export default new LocaleService();
