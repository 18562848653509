<template>
  <div class="card">
    <div class="reverse-mapping-summary">
      <div class="card-body" @click="openModal">
        <h5 class="card-title">
          {{ akeneoAttributeCode }} → {{ bynderFieldCode }}
        </h5>
        <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
          <span> {{ formattedMessages }} </span>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t('job.jobInstance.bynderAkeneo.config.reverse_mapping.title') }}
            </h5>
          </div>
          <div class="modal-body">
            <h5>{{ $t('form.bynderAkeneo.reverse_mapping.source.title') }}</h5>
            <TextField
                class=""
                id="akeneo_attribute_code"
                :required=true
                :label="$t('form.bynderAkeneo.reverse_mapping.akeneo_attribute_code')"
                :error="getErrorMessage('akeneoAttributeCode')"
                v-model="akeneoAttributeCode"
            />
            <TextField
                class=""
                id="akeneo_channel"
                :label="$t('form.bynderAkeneo.reverse_mapping.akeneo_locale')"
                :error="getErrorMessage('akeneo_locale')"
                v-model="akeneoLocale"
            />
            <TextField
                class=""
                id="akeneo_channel"
                :label="$t('form.bynderAkeneo.reverse_mapping.akeneo_channel')"
                :error="getErrorMessage('akeneo_channel')"
                v-model="akeneoChannel"
            />
            <TextField
                class=""
                id="akeneo_label_locale"
                :label="$t('form.bynderAkeneo.reverse_mapping.akeneo_label_locale.label')"
                :tooltip="$t('form.bynderAkeneo.reverse_mapping.akeneo_label_locale.tooltip')"
                :error="getErrorMessage('akeneo_label_locale')"
                v-model="akeneoLabelLocale"
            />
            <TextField
                class=""
                id="reference_entity_attribute"
                :required=false
                :label="$t('form.bynderAkeneo.reverse_mapping.reference_entity_attribute.label')"
                :tooltip="$t('form.bynderAkeneo.reverse_mapping.reference_entity_attribute.tooltip')"
                :error="getErrorMessage('akeneo_reference_entity_attribute')"
                v-model="akeneoReferenceEntityAttribute"
            />
            <ToggleField
                class="mb-3"
                id="multiple_values"
                :label="$t('form.bynderAkeneo.reverse_mapping.multiple_values')"
                :error="getErrorMessage('multiple_values')"
                v-model="multipleValues"
            />
            <TextField
                v-if="multipleValues"
                id="separator"
                :label="$t('form.bynderAkeneo.reverse_mapping.separator')"
                :error="getErrorMessage('separator')"
                v-model="separator"
            />
            <h5>{{$t('form.bynderAkeneo.reverse_mapping.destination.title')}}</h5>
            <TextField
                class=""
                id="bynder_field_code"
                :required=true
                :label="$t('form.bynderAkeneo.reverse_mapping.bynder_field_code')"
                :error="getErrorMessage('bynderFieldCode')"
                v-model="bynderFieldCode"
            />
          </div>
          <div class="modal-footer">
            <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
              <span> {{ formattedMessages }} </span>
            </div>
            <button @click="removeReverseMapping" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-trash2" aria-hidden="true"/>
              {{ $t('common.remove') }}
            </button>
            <button @click="saveReverseMapping" class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-check" aria-hidden="true"/>
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import TextField from "@/components/Form/TextField";
import ToggleField from "@/components/Form/ToggleField";

export default {
  name: "ReverseMappingField",
  components: {
    TextField,
    ToggleField
  },
  props: {
    id: String,
    reverseMapping: Object,
    errors: Object,
  },
  data () {
    return {
      modal: null,
      name: this.reverseMapping.name,
      akeneoAttributeCode: this.reverseMapping.akeneoProductField.attributeCode,
      akeneoLocale: this.reverseMapping.akeneoProductField.locale,
      akeneoChannel: this.reverseMapping.akeneoProductField.channel,
      akeneoLabelLocale: this.reverseMapping.akeneoProductField.labelLocale,
      akeneoReferenceEntityAttribute: this.reverseMapping.akeneoProductField.referenceEntityAttribute ?? null,
      bynderFieldCode: this.reverseMapping.bynderMediaField.fieldCode,
      multipleValues: this.reverseMapping.multipleValues ?? false,
      separator: this.reverseMapping.separator ?? ",",
      errorMessage: this.error,
      formErrors: {}
    }
  },
  computed: {
    formattedMessages () {
      let formattedMessages = "";
      if (this.errors && Object.keys(this.errors).length !== 0) {
        for (const key in this.errors) {
          formattedMessages += `${key}: ${this.errors[key]}\n`;
        }
      }

      if (Object.keys(this.formErrors).length !== 0) {
        for (const key in this.formErrors) {
          formattedMessages += `${key}: ${this.formErrors[key]}\n`;
        }
      }

      return formattedMessages;
    },
  },
  methods: {
    openModal () {
      this.modal.show();
    },
    saveReverseMapping (e) {
      e.preventDefault();

      this.formErrors          = {};
      const requiredProperties = ['akeneoAttributeCode', 'bynderFieldCode'];
      for (const requiredProperty of requiredProperties) {
        if (!this[requiredProperty] || this[requiredProperty] === "") {
          this.formErrors[requiredProperty] = this.$t('common.isRequired');
        }
      }

      if (Object.keys(this.formErrors).length > 0) {
        return;
      }

      this.modal.hide();

      let conf = {
        name: this.name,
        akeneoProductField: {
          attributeCode: this.akeneoAttributeCode,
          locale: this.akeneoLocale,
          channel: this.akeneoChannel,
          labelLocale: this.akeneoLabelLocale,
          referenceEntityAttribute: this.akeneoReferenceEntityAttribute,
        },
        bynderMediaField: {
          fieldCode: this.bynderFieldCode,
        },
        multipleValues: this.multipleValues,
        separator: this.separator,
      };

      this.$emit('reverse-mapping-save', conf);
    },
    removeReverseMapping (e) {
      if (confirm(this.$t('form.bynderAkeneo.reverse_mapping.delete_confirmation'))) {
        this.modal.hide();
        this.$emit('reverse-mapping-remove');
      }
      e.preventDefault();
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
    addIfDefinedAndNotEmpty (obj, propName, propValue) {
      if (propValue !== undefined && propValue !== null && propValue !== "") {
        obj[propName] = propValue;
      }
    }
  },
  mounted () {
    this.modal = new Modal(this.$refs.exampleModal)
    if (this.akeneoAttributeCode === "" || this.bynderFieldCode === "") {
      this.modal.show();
    }
  },
}
</script>

<style lang="scss">

.reverse-mapping-summary {
  cursor: pointer;

  .card-body {
    min-height: 120px;
  }

  :hover {
    background-color: lightgray;
  }
}

</style>
