<template>
  <div class="mb-3">
    <label
        v-if="label"
        class="form-label">
      {{ label }}
    </label>

    <div class="container ms-0">
      <div class="row col-9">
        <div class="col-lg-4 col-md-6" v-for="(reverseMapping, index) in reverseMappings" :key="confInputKey + index">
          <ReverseMappingField
              class="mt-3"
              :id="'Reverse_mapping-' + index"
              :reverseMapping=reverseMapping
              v-on:reverse-mapping-save="saveReverseMapping($event, index)"
              @reverse-mapping-remove="removeReverseMapping(index)"
              :errors="this.getReverseMappingErrors(index)"
          />
        </div>
      </div>
    </div>
    <button @click="addReverseMapping" class="m-3 btn btn-primary">
      <i class="bi bi-plus" aria-hidden="true"/>
      {{ $t('form.bynderAkeneo.reverse_mapping.add_reverse_mapping') }}
    </button>
  </div>
</template>

<script>

import ReverseMappingField from "./ReverseMappingField";

export default {
  name: "ReverseMappingFields",
  components: {ReverseMappingField},
  props: {
    id: String,
    label: String,
    modelValue: Array,
    errors: Object,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confInputKey: Math.random(),
      reverseMappings: this.modelValue
    }
  },
  methods: {
    getReverseMappingErrors (index) {
      if (this.errors && this.errors[index]) {
        return this.errors[index];
      }
      return null;
    },
    addReverseMapping (e) {
      if (!this.reverseMappings) {
        this.reverseMappings = [];
      }

      this.reverseMappings.push({
        name: "",
        akeneoProductField: {
          attributeCode: "",
          locale: "",
          channel: "",
          labelLocale: "",
        },
        bynderMediaField: {
          fieldCode: ""
        },
        multipleValues: false,
        separator: ",",
      });

      e.preventDefault();
    },
    saveReverseMapping (reverseMapping, index) {
      this.reverseMappings[index] = reverseMapping;
      this.updateValue();
    },
    removeReverseMapping (index) {
      this.reverseMappings.splice(index, 1);
      this.updateValue();
    },
    updateValue () {
      this.$emit(`update:modelValue`, this.reverseMappings);
      this.confInputKey = Math.random()
    }
  },
}
</script>
