<template>
  <div>
    <vue-json-pretty :data="JSON.parse(val)"/>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: "JsonFormatter",
  components: {
    VueJsonPretty
  },
  props: ['val']
}
</script>
