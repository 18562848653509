const appStyleMixin = {
    methods: {
        getAppStyle () {
            return process.env.VUE_APP_STYLE;
        },
        getImage (imageName) {
            return require('@/assets/images/' + this.getAppStyle() + '/' + imageName);
        },
        getFooterLink() {
            switch (this.getAppStyle()) {
                case 'bynder':
                    return 'https://www.bynder.com/';
                case 'dataggo':
                    return 'https://www.dataggo.com/';
            }
        },
        getFooterLinktext() {
            switch (this.getAppStyle()) {
                case 'bynder':
                    return 'www.bynder.com';
                case 'dataggo':
                    return 'www.dataggo.com';
            }
        }
    }
}

export default appStyleMixin
