<template>
  <div class="card">
    <div class="mapping-summary">
      <div class="card-body" @click="openModal">
        <h5 class="card-title">
          {{ asset_family }}
        </h5>
        <h5 class="card-title">
          {{ bynder_id_attribute_code }}
        </h5>
        <p class="card-text">
          {{ $t('form.bynderAkeneo.mapping.conditions.count', {n: conditions.length}) }}
        </p>
        <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
          <span> {{ formattedMessages }} </span>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t('form.bynderAkeneo.delete_mapping.card.label', {code: asset_family}) }}
            </h5>
          </div>
          <div class="modal-body">
            <TextField
                class="w-75"
                id="akeneoAssetFamily"
                :required=true
                :label="$t('form.bynderAkeneo.delete_mapping.akeneo_asset_family')"
                :error="getErrorMessage('asset_family')"
                v-model="asset_family"
            />
            <TextField
                class="w-75"
                :required=true
                :label="$t('form.bynderAkeneo.delete_mapping.bynder_id_attribute_code')"
                :error="getErrorMessage('bynder_id_attribute_code')"
                v-model="bynder_id_attribute_code"
            />
            <p class="w-75">{{ $t('job.jobInstance.bynderAkeneo.config.delete_mapping.conditions.description') }}</p>
            <BynderFilterFields
                id="conditions"
                :label="$t('form.bynderAkeneo.mapping.conditions')"
                :errors="getErrorMessage('conditions')"
                v-model="conditions"
            />
          </div>
          <div class="modal-footer">
            <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
              <span> {{ formattedMessages }} </span>
            </div>
            <button @click="removeMapping" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-trash2" aria-hidden="true"/>
              {{ $t('common.remove') }}
            </button>
            <button @click="saveMapping" class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-check" aria-hidden="true"/>
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import TextField from "@/components/Form/TextField";
import BynderFilterFields from "@/components/Job/JobInstance/Configuration/Bynder/BynderFilterFields";

export default {
  name: "DeleteMappingField",
  components: {
    TextField,
    BynderFilterFields,
  },
  props: {
    id: String,
    mapping: Object,
    errors: Object,
  },
  data () {
    return {
      modal: null,
      asset_family: this.mapping.asset_family ?? "",
      bynder_id_attribute_code: this.mapping.bynder_id_attribute_code ?? "",
      conditions: this.mapping.conditions ?? [],
      errorMessage: this.error,
      formErrors: {},
    }
  },
  computed: {
    formattedMessages () {
      let formattedMessages = "";
      if (this.errors && Object.keys(this.errors).length !== 0) {
        for (const key in this.errors) {
          const message = typeof this.errors[key] === 'string' ? this.errors[key] : 'invalid'
          formattedMessages += `${key}: ${message}\n`;
        }
      }

      if (Object.keys(this.formErrors).length !== 0) {
        for (const key in this.formErrors) {
          formattedMessages += `${key}: ${this.formErrors[key]}\n`;
        }
      }

      return formattedMessages;
    },
  },
  methods: {
    openModal () {
      this.modal.show();
    },
    saveMapping (e) {
      e.preventDefault();

      this.formErrors = {};
      if (this.asset_family === "" ) {
        this.formErrors.asset_family = this.$t('common.isRequired');

        return;
      }
      if (this.bynder_id_attribute_code === "") {
        this.formErrors.bynder_id_attribute_code = this.$t('common.isRequired');

        return;
      }

      this.modal.hide();

      let saveData = {
        asset_family: this.asset_family,
        bynder_id_attribute_code: this.bynder_id_attribute_code,
        conditions: this.conditions,
      }

      this.$emit('mapping-save', saveData);
    },
    removeMapping (e) {
      if (confirm(this.$t('form.bynderAkeneo.mapping.delete_confirmation'))) {
        this.modal.hide();
        this.$emit('mapping-remove');
      }

      e.preventDefault();
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
  },
  mounted () {
    this.modal = new Modal(this.$refs.exampleModal)
    if (this.akeneo_asset_family === "" && this.reference_entity === "") {
      this.modal.show();
    }
  },
}
</script>

<style lang="scss">

.mapping-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
}

</style>
