<template>
  <div class="mt-3">
    <h5>{{ $t('job.jobInstance.shopify.config.bynder_configuration') }}</h5>
    <ConnectionSelectField
        class="w-75 mb-3"
        id="bynderConnectionFilter"
        type="bynder"
        :label="$t('job.jobInstance.shopify.config.bynder_connection')"
        :onUpdate="(v) => {this.bynderConnection = v}"
        :baseValue="this.bynderConnection"
        :company-code="this.jobInstance.companyCode"
        :error="this.getPropertyErrors(['bynder_media_to_shopify_check_bynder_metadata', 'bynder_connection'])"
    />
    <h5>{{ $t('job.jobInstance.shopify.config.shopify_configuration') }}</h5>
    <ConnectionSelectField
        class="w-75 mb-3"
        id="shopifyConnectionFilter"
        type="shopify"
        :label="$t('job.jobInstance.shopify.config.shopify_connection')"
        :onUpdate="(v) => {this.shopifyConnection = v}"
        :baseValue="this.shopifyConnection"
        :company-code="this.jobInstance.companyCode"
        :error="this.getPropertyErrors(['check_shopify_session', 'shopify_connection'])"
    />
    <h5>{{ $t('job.jobInstance.shopify.config.assetToProduct.title') }}</h5>
    <p class="w-75">{{ $t('job.jobInstance.shopify.config.assetToProduct.description') }}</p>
    <ProductMappingFields
        id="product-mappings"
        v-model="assetToProduct"
        :errors="this.getPropertyErrors(['bynder_media_to_shopify_product_image', 'assetToProduct'])"
    />
    <h5>{{ $t('job.jobInstance.shopify.config.assetToFile.title') }}</h5>
    <ToggleField
        class="w-75"
        id="assetToFileEnabled"
        :label="$t('common.property.enabled')"
        v-model="assetToFileEnabled"
        :error="this.getPropertyErrors(['bynder_media_to_shopify_product_image', 'assetToFile', 'enabled'])"
    />
    <TextField
        class="w-75"
        id="fileAltMetadata"
        v-if="assetToFileEnabled"
        :label="$t('job.jobInstance.shopify.config.bynder_configuration.image_alt')"
        v-model="fileAltMetadata"
        :error="this.getPropertyErrors(['bynder_media_to_shopify_product_image', 'assetToFile', 'imageAltMetadata'])"
        :tooltip="$t('job.jobInstance.bynderShopify.config.shopify_file_collection.alt_text')"
    />
    <TextField
        class="w-75"
        id="fileUrlPreset"
        v-if="assetToFileEnabled"
        :label="$t('job.jobInstance.shopify.config.bynder_configuration.fileUrlPreset')"
        v-model="fileUrlPreset"
        :required="true"
        :error="this.getPropertyErrors(['bynder_media_to_shopify_product_image', 'assetToFile', 'urlPreset'])"
        :tooltip="$t('job.jobInstance.bynderShopify.config.shopify_file_collection.file_url_preset')"
    />
    <h6 v-if="assetToFileEnabled" class="mt-3 mb-0">{{ $t('job.jobInstance.shopify.config.filters') }}</h6>
    <BynderFilterFields
        id="conditions"
        v-if="assetToFileEnabled"
        v-model="fileConditions"
        :errors="this.getPropertyErrors(['bynder_media_to_shopify_product_image', 'assetToFile', 'conditions'])"
    />
    <h5>{{ $t('job.jobInstance.shopify.config.others') }}</h5>
    <ToggleField
        id="delta"
        class="w-75"
        :label="$t('job.jobInstance.shopify.config.delta')"
        v-model="delta"
        :error="this.getPropertyErrors(['bynder_media_to_shopify_product_image', 'delta'])"
    />
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import jobConfigurationMixin from "@/mixins/jobConfigurationMixin";
import BynderFilterFields from "@/components/Job/JobInstance/Configuration/Bynder/BynderFilterFields";
import ToggleField from "@/components/Form/ToggleField";
import TextField from "@/components/Form/TextField";
import ConnectionSelectField from "@/views/Connection/ConnectionSelectField";
import ProductMappingFields
  from "@/components/Job/JobInstance/Configuration/BynderShopify/Mapping/ProductMappingFields.vue";

export default {
  name: "ConfigurationForm",
  mixins: [jobConfigurationMixin],
  components: {
    ProductMappingFields,
    ConnectionSelectField,
    BynderFilterFields,
    ToggleField,
    TextField,
  },
  props: {
    modelValue: Object,
    errors: Object,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      jobInstance: this.modelValue,
      configuration: JSON.parse(this.modelValue.configuration),
    }
  },
  computed: {
    bynderConnection: {
      get () {
        return this.getJobConfiguration(["bynder_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["bynder_connection"], v)
      },
    },
    assetToProduct: {
      get () {
        return this.getJobConfiguration(["assetToProduct"]);
      },
      set (v) {
        this.setJobConfiguration(["assetToProduct"], v)
      },
    },
    shopifyConnection: {
      get () {
        return this.getJobConfiguration(["shopify_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["shopify_connection"], v)
      },
    },
    assetToFileEnabled: {
      get () {
        return !!this.getJobConfiguration(["assetToFile", "enabled"]);
      },
      set (v) {
        this.setJobConfiguration(["assetToFile", "enabled"], v)
      },
    },
    fileUrlPreset: {
      get () {
        return this.getJobConfiguration(["assetToFile", "urlPreset"]);
      },
      set (v) {
        this.setJobConfiguration(["assetToFile", "urlPreset"], v)
      },
    },
    fileAltMetadata: {
      get () {
        return this.getJobConfiguration(["assetToFile", "imageAltMetadata"]);
      },
      set (v) {
        this.setJobConfiguration(["assetToFile", "imageAltMetadata"], v)
      },
    },
    fileConditions: {
      get () {
        return this.getJobConfiguration(["assetToFile", "conditions"]);
      },
      set (v) {
        this.setJobConfiguration(["assetToFile", "conditions"], v)
      },
    },
    delta: {
      get () {
        return !!this.getJobConfiguration(["delta"]);
      },
      set (v) {
        this.setJobConfiguration(["delta"], v)
      },
    }
  },
}
</script>

<style scoped lang="scss">
</style>
