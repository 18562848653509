import authHeader from './Auth/auth-header';
import apiClient from "./api-client";

class UserService {
    getUserFromId(id) {
        return apiClient().get('/api/users/' + id, {headers: authHeader()});
    }

    getResetPasswordLink(id, appStyle) {
        return apiClient().get('/api/users/' + id + '/reset-password-link?app_style=' + appStyle, {headers: authHeader()});
    }
    updateOrCreateUser(user) {
        if(user.companyCode) {
            user.company = "/api/companies/" + user.companyCode;
            delete user.companyCode;
        }

        if (user.id) {
            return apiClient().patch('/api/users/' + user.id, user, {headers: authHeader()});
        }

        return apiClient().post('/api/users', user, {headers: authHeader()});
    }

    getUsers(page = 1, params = {}) {
        let config = {
            headers: authHeader(),
            params: {
                "order[id]": 'desc',
                page: page,
                ...params
            }
        }

        return apiClient().get('/api/users', config);
    }

    removeUser(id) {
        return apiClient().delete('/api/users/' + id, {headers: authHeader()});
    }
}

export default new UserService();
