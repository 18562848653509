<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <p>
        <a href="https://api.akeneo.com/documentation/filter.html#filter-on-product-properties" target="_blank">{{$t('connection.data.akeneo.details_link')}}</a>.
      </p>
      <NumberField
          id="page"
          class="w-100"
          :label="$t('page')"
          v-model="page"
      />
      <p>{{ $t('connection.data.search') }}</p>
      <JsonField
          class=""
          id="search"
          :rows=3
          :modes="['code']"
          v-model="search"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import JsonField from "@/components/Form/JsonField.vue";
import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import NumberField from "@/components/Form/NumberField.vue";

export default {
  name: "AkeneoProductSearchDataRequest",
  components: {
    NumberField,
    GenericDataRequest,
    JsonField,
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      search: '{}',
      page: 1,
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getAkeneoData(this.connection.id, 'product_search', {
        search: this.search,
        page: this.page
      })
    }
  },
}
</script>

