<template>
  {{ formatDate }}
</template>

<script>
import moment from 'moment';

export default {
  name: "DateFormatter",
  props: {
    value: String,
    format: {
      type: String,
      default: 'LLL'
    }
  },
  computed: {
    formatDate() {
      moment.locale(this.$i18n.locale);
      return moment(String(this.value)).format(this.format);
    }
  }
}
</script>
