<template>
  <div class="form-floating mb-3">
    <input
        type="password"
        class="form-control"
        :class="error ? 'is-invalid' : ''"
        :id="id"
        :placeholder="label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :required="required"
    />
    <label
        :for="id"
        class="form-label w-100">
      <div class="d-inline-block">{{ label }}</div>
      <div v-if="required" class="d-inline-block">*</div>
    </label>
    <div v-if="error" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordField",
  props: {
    id: String,
    label: String,
    modelValue: String,
    error: String,
    required: {
      type: Boolean,
      default: false
    },
  }
}
</script>
