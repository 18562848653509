<template>
  <div class="mt-3">
    <h5>{{ $t('job.jobInstance.bynder_rule.config.bynder_configuration.title') }}</h5>
    <ConnectionSelectField
        class="w-75 mb-3"
        id="bynderConnectionFilter"
        type="bynder"
        :label="$t('job.jobInstance.bynder_rule.config.bynder_connection')"
        :onUpdate="(v) => {this.bynderConnection = v}"
        :baseValue="this.bynderConnection"
        :company-code="this.jobInstance.companyCode"
        :error="this.getPropertyErrors(['check_bynder_config', 'bynder_connection'])"
    />
  </div>
  <h5>{{ $t('job.jobInstance.bynder_rule.config.rules.title') }}</h5>
  <p class="w-75">{{ $t('job.jobInstance.bynder_rule.config.rules.description') }}</p>
  <BynderRuleFields
      id="rules"
      v-model="rules"
      :errors="this.getPropertyErrors(['bynder_execute_rules', 'rules'])"
  />
  <h5>{{ $t('job.jobInstance.bynder_rule.config.general.title') }}</h5>
  <ToggleField
      class="w-75"
      id="delta"
      :label="$t('job.jobInstance.bynder_rule.config.general.delta')"
      v-model="delta"
      :error="this.getPropertyErrors(['bynder_execute_rules', 'delta'])"
  />
  <DateTimeField
      v-if="delta"
      id="deltaDate"
      :label="$t('job.jobInstance.bynder_rule.config.general.deltaDate')"
      v-model="deltaDate"
      :format="'YYYY-MM-DD[T]HH:mm:ss[Z]'"
      :error="this.getPropertyErrors(['bynder_execute_rules', 'deltaDate'])"
  />
</template>

<script>
import ConnectionSelectField from "@/views/Connection/ConnectionSelectField";
import jobConfigurationMixin from "@/mixins/jobConfigurationMixin";
import BynderRuleFields from "@/components/Job/JobInstance/Configuration/Bynder/Rules/BynderRuleFields";
import {useToast} from "vue-toastification";
import ToggleField from "@/components/Form/ToggleField.vue";
import DateTimeField from "@/components/Form/DateTimeField.vue";

export default {
  name: "BynderRulesConfigurationForm",
  mixins: [jobConfigurationMixin],
  components: {
    DateTimeField,
    ToggleField,
    BynderRuleFields,
    ConnectionSelectField,
  },
  props: {
    confInputKey: Number,
    modelValue: Object,
    errors: Object,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      jobInstance: this.modelValue,
      configuration: JSON.parse(this.modelValue.configuration),
    }
  },
  computed: {
    bynderConnection: {
      get () {
        return this.getJobConfiguration(["bynder_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["bynder_connection"], v)
      },
    },
    rules: {
      get () {
        return this.getJobConfiguration(["rules"], []);
      },
      set (v) {
        this.setJobConfiguration(["rules"], v)
      },
    },
    delta: {
      get () {
        return !!this.getJobConfiguration(["delta"]);
      },
      set (v) {
        this.setJobConfiguration(["delta"], v)
      },
    },
    deltaDate: {
      get () {
        return this.getJobConfiguration(["deltaDate"]);
      },
      set (v) {
        if (this.getJobConfiguration(["delta"]) && v !== "Invalid date") {
          this.setJobConfiguration(["deltaDate"], v)
        } else {
          this.setJobConfiguration(["deltaDate"], null)
        }
      },
    },
  }
}
</script>

<style scoped lang="scss">
</style>
