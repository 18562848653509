<template>
  <GenericConnectionSummary
    :connection="connection"
    :hasDataRoute="hasDataRoute"
    :connectionType="connectionType"
  >
    <template v-slot:card-content>
      <h5 class="card-title">{{ connection.label }}</h5>
      <p class="card-text">{{ $t('connection.summary.akeneo.url', {url: connection.url}) }}</p>
      <p class="card-text">{{ $t('connection.summary.company', {company: connection.company.name}) }}</p>
      <p class="card-text">
        {{ connection.version ? $t('connection.summary.version', {version: connection.version}) : '' }}</p>
    </template>
  </GenericConnectionSummary>
</template>
<script>

import GenericConnectionSummary from "@/views/Connection/Summary/GenericConnectionSummary.vue";

export default {
  name: "AkeneoConnectionSummary",
  components: {GenericConnectionSummary},
  props: {
    connectionType: String,
    connection: Object,
    hasDataRoute: Boolean,
  },
  data () {
    return {
      errors: {},
      success: {},
    }
  },
}
</script>
