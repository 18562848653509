<template>
  <Loader v-if="isLoading"/>
  <template v-if="!isLoading">
      <table v-if="items.length > 0" class="table table-hover">
        <thead>
        <tr>
          <th scope="col">{{ $t('versioning.property.version') }}</th>
          <th scope="col">{{ $t('versioning.property.username') }}</th>
          <th scope="col">{{ $t('versioning.property.date') }}</th>
          <th scope="col">{{ $t('versioning.property.changes') }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in items" :key="item.version">
          <tr class="main-row" data-bs-toggle="collapse" :data-bs-target="'#details-' + item.version"
              aria-expanded="false" :aria-controls="'details-' + item.version">
            <td>
              <span class="badge fs-6 bg-secondary">{{ item.version }}</span>
            </td>
            <td>{{ item.username }}</td>
            <td>
              <DateFormatter :value="item.loggedAt" format="DD/MM/YYYY HH:mm:ss"/>
            </td>
            <td>
              {{ listChangeProperties(item) }}
            </td>
          </tr>
          <tr class="details-row">
            <td></td>
            <td colspan="12">
              <div class="collapse" :id="'details-' + item.version">
                <table class="table">
                  <tbody>
                    <template v-for="(change, index) in item.changeSet" :key="index">
                      <tr class="change-title">
                        <td colspan="2">
                          <span class="fw-bolder">{{ index }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="d-block text-danger">Old</span>
                          <ValueFormatter :val="change['old']"/>
                        </td>
                        <td>
                          <span class="d-block text-success">New</span>
                          <ValueFormatter :val="change['new']"/>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
      <div v-else class="text-center">
        <img :src="require('@/assets/versioning/no_history.png')" width="100">
        <h3>{{ $t('versioning.no_history_found') }}</h3>
      </div>
  </template>
</template>

<script>
import Loader from "@/components/Page/Loader";
import VersioningService from "@/services/versioning.service";
import DateFormatter from "@/components/Utils/DateFormatter";
import ValueFormatter from "@/components/Utils/ValueFormatter";

export default {
  name: "HistoryGrid",
  components: {
    ValueFormatter,
    DateFormatter,
    Loader
  },
  props: {
    entityIdentifier: String,
    entityType: String
  },
  data() {
    return {
      isLoading: false,
      items: []
    }
  },
  methods: {
    listChangeProperties(item) {
      return Object.keys(item.changeSet).join(', ');
    },
    async loadVersioning() {
      this.isLoading = true;
      await VersioningService.getVersioning(this.entityIdentifier, this.entityType)
          .then(response => {
            this.items = response.data['hydra:member'];
          })
          .catch((error) => {
            this.toast.error(this.$i18n.t('common.errorOccurred'));
            console.log(error);
          });
      this.isLoading = false;
    }
  },
  async beforeMount() {
    await this.loadVersioning();
  }
}
</script>

<style scoped lang="scss">
table {
  tr {
    &.main-row {
      cursor: pointer;
      td {
        border: none;
      }
    }

    &.details-row {
      td {
        padding: 0;
        box-shadow: none !important;
      }
      table {
        margin-bottom: 0;
        tbody {
          tr {
            td {
              padding: 5px;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
            &.change-title {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
