<template>
  <Loader v-if="isLoaded"/>
  <div v-if="!isLoaded" class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="title">
      <template v-slot:under-title-area>
        <p class="d-block text-muted">
          {{ $t('job.job_instances.job') }} : {{ $t('job.' + jobInstance.jobCode) }}
          <br/>
          {{ $t('common.property.createdAt') }}:
          <DateFormatter :value="jobInstance.createdAt"/>
          |
          {{ $t('common.property.updatedAt') }}:
          <DateFormatter :value="jobInstance.updatedAt"/>
        </p>
        <span v-if="linkInstallationGuide">
          <a :href="linkInstallationGuide">{{ $t('guide.installation_guide') }}</a>
          <br/>
        </span>

        <span class="text-muted">
            {{ jobInstance.description }}
        </span>
      </template>
      <template v-slot:button-area>
        <button v-if="isGranted('ROLE_ADMIN')" type="button" class="m-1 btn btn-danger" :disabled="isDeleting"
                data-bs-toggle="modal"
                data-bs-target="#confirm-deletion">
          <i class="bi bi-trash2" aria-hidden="true"/>
          {{ $t('common.delete') }}
        </button>
        <ConfirmationModal v-if="isGranted('ROLE_ADMIN')" modal-id="confirm-deletion" title="Confirm deletion"
                           :action="deleteJob">
          <template v-slot:content>
            {{ $t('job.job_instance.delete_confirmation_text') }}
            <br/>
            {{ $t('common.please_confirm') }}
          </template>
        </ConfirmationModal>
        <router-link class="m-1 btn btn-primary" :to="editLink">
          <i class="bi bi-pencil" aria-hidden="true"/>
          {{ $t('common.edit') }}
        </router-link>
      </template>
    </HeaderTitle>
    <div class="text-center m-lg-5">
      <div class="btn-group">
        <button
            type="button"
            class="btn btn-lg btn-success"
            :disabled="isLaunchingJob || !jobInstance.enabled"
            @click="launchJob(null)">
          <i class="bi bi-play"></i>
          {{ $t('job.job_instances.launch') }}
        </button>
        <button
            type="button"
            class="btn btn-lg btn-success dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="isLaunchingJob || !jobInstance.enabled">
          <span class="visually-hidden">{{ $t('job.job_instances.launch_options') }}</span>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="#" @click.prevent="launchJob(false)">
              {{ $t('job.job_instances.launch_full') }}
            </a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click.prevent="launchJob(true)">
              {{ $t('job.job_instances.launch_delta') }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div>
      <h5 class="me-auto">{{ $t('job.job_instances.last_executions') }}</h5>
      <hr>

      <Loader v-if="isLastExecutionsLoading"/>
      <JobExecutionGrid :hide-job-instance-info="true" v-if="!isLastExecutionsLoading" :items="lastExecutions"/>
    </div>
  </div>
</template>

<script>
import Loader from "../../../components/Page/Loader";
import JobService from "../../../services/job.service";
import HeaderTitle from "../../../components/Page/Content/HeaderTitle";
import {useToast} from "vue-toastification";
import JobExecutionGrid from "../../../components/Job/JobExecution/JobExecutionGrid";
import DateFormatter from "../../../components/Utils/DateFormatter";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import ConfirmationModal from "@/components/Utils/ConfirmationModal";
import SecurityMixin from "@/mixins/securityMixin";

export default {
  name: "JobInstanceView",
  components: {ConfirmationModal, Breadcrumbs, DateFormatter, JobExecutionGrid, HeaderTitle, Loader},
  mixins: [SecurityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      isLoaded: false,
      jobInstance: {},
      isLastExecutionsLoading: false,
      isLaunchingJob: false,
      isDeleting: false,
      lastExecutions: [],
      timer: null,
    }
  },
  computed: {
    editLink () {
      return "/job_instance/" + this.jobInstance.code + "/edit";
    },
    title () {
      return this.$t('job.job_instances') + " - " + (this.jobInstance.label ?? this.jobInstance.code);
    },
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'job_instances', label: this.$i18n.t('job.job_instances'), link: '/job_instance'},
        {name: 'job_instances', label: this.jobInstance.code, isCurrent: 'true'},
      ]
    },
    linkInstallationGuide () {
      switch (this.jobInstance.jobCode) {
        case "bynder_media_to_product_image_sync":
          return "/guides/bynder-shopify-setup";
        default:
          return null;
      }
    },
  },
  methods: {
    deleteJob () {
      this.isDeleting = true;

      JobService.deleteJob(this.jobInstance)
          .then(() => {
            this.toast.success(this.$i18n.t('job.job_instances.delete_success'));
            this.$router.push('/job_instance');
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    },
    launchJob (deltaOverride) {
      this.isLaunchingJob = true;
      JobService.launchJob(this.jobInstance, deltaOverride)
          .then(() => {
            this.toast.success(this.$i18n.t('job.job_instances.launch_job_success'));
            this.loadLastExecutions();
          })
          // TODO Handle error better than this.
          .catch((error) => {
            this.toast.error(this.$i18n.t(error.response.data['hydra:description']));
          })
          .finally(() => {
            this.isLaunchingJob = false;
          });
    },
    loadLastExecutions () {
      JobService.getLastJobExecutions(this.jobInstance.id)
          .then((response) => {
            this.isLastExecutionsLoading = false;
            this.lastExecutions          = response.data['hydra:member'];

          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    }
  },
  beforeMount () {
    this.isLoaded = true;
    JobService.getJobInstance(this.$route.params.code)
        .then((response) => {
          this.isLoaded    = false;
          this.jobInstance = response.data;

          this.isLastExecutionsLoading = true;
          this.loadLastExecutions()
        })
        // TODO Handle error better than this.
        .catch(error => console.log(error));
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.loadLastExecutions()
    }, 5000);
  },
  beforeUnmount () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
