<template>
  <div class="wrapper">
    <NavBar/>
    <div class="container-fluid">
      <router-view/>
    </div>
  </div>
  <Footer/>
</template>

<script>
import NavBar from "./components/Page/NavBar";
import Footer from "@/components/Page/Footer";
import appStyleMixin from "@/mixins/appStyleMixin";

export default {
  mixins: [appStyleMixin],
  mounted () {
    // Set favicon
    const favicon = document.getElementById("favicon");
    favicon.href  = this.getImage('favicon.png');

    // Set title
    document.title = this.$t('app_style.' + process.env.VUE_APP_STYLE + '.title');
  },
  components: {Footer, NavBar}
}
</script>

<style>
html, body, #app {
  height: 100%;
}

.wrapper {
  display: flex;
  min-height: -webkit-calc(100% - 120px);
  min-height: -moz-calc(100% - 120px);
  min-height: calc(100% - 120px);
  flex-direction: column;
}
</style>
