<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <TextField
          id="attributeCode"
          class="w-100"
          :label="$t('connection.data.akeneo.attribute_code')"
          v-model="attributeCode"
      />
      <NumberField
          id="page"
          class="w-100"
          :label="$t('connection.data.akeneo.page')"
          v-model="page"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import NumberField from "@/components/Form/NumberField.vue";
import TextField from "@/components/Form/TextField.vue";

export default {
  name: "AkeneoAttributeOptionDataRequest",
  components: {
    TextField,
    NumberField,
    GenericDataRequest,
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      attributeCode: '',
      page: 1,
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getAkeneoData(this.connection.id, 'attribute_option', {
        attributeCode: this.attributeCode,
        page: this.page
      })
    }
  },
}
</script>

