<template>
  {{ implodeValue }}
</template>

<script>
export default {
  name: "ArrayFormatter",
  props: ['val'],
  computed: {
    implodeValue() {
      return this.val.join(', ');
    }
  }
}
</script>
