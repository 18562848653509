<template>
  <div class="card">
    <div v-if="!editMode" class="media-url-summary">
      <div class="card-body" @click="enterEditMode">
        <div class="media-url-buttons">
          <i class="bi bi-arrows-angle-expand" @click="enterEditMode"/>
        </div>
        <h5 class="card-title">{{ $t('form.media_url.title') }}</h5>
        <p class="card-text">{{ $t('form.media_url.bynder_field.show', {bynder_field: bynderMetadata}) }}</p>
        <p class="card-text">{{ $t('form.media_url.akeneo_attribute.show', {akeneo_attribute: attribute}) }}</p>
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
      </div>
    </div>
    <div v-if="editMode" class="card-body">
      <div class="media-url-buttons">
        <i class="bi bi-trash2 me-2" @click="removeMediaUrl"/>
        <i class="bi bi-arrows-angle-contract" @click="closeEditMode"/>
      </div>
      <h5 class="card-title">{{ $t('form.media_url.title') }}</h5>
      <div>
        <TextField
            class="w-75"
            id="bynderField"
            :label="$t('form.media_url.bynder_field.title')"
            v-model="bynderMetadata"
            @change="saveMediaUrl"
        />
        <TextField
            class="w-75"
            id="akeneoAttribute"
            :label="$t('form.media_url.akeneo_attribute.title')"
            v-model="attribute"
            @change="saveMediaUrl"
        />
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TextField from "../../../../../Form/TextField";

export default {
  name: "MadiaUrlField",
  components: {
    TextField,
  },
  props: {
    id: String,
    mediaUrlData: Object,
    error: String,
  },
  data () {
    return {
      editMode: this.mediaUrlData.bynderMetadata === "",
      bynderMetadata: this.mediaUrlData.bynderMetadata,
      attribute: this.mediaUrlData.attribute,
    }
  },
  methods: {
    enterEditMode () {
      this.editMode = true;
    },
    closeEditMode () {
      this.editMode = false;
    },
    saveMediaUrl (e) {
      e.preventDefault();
      this.$emit('media-url-save', {
        bynderMetadata: this.bynderMetadata,
        attribute: this.attribute,
      });
    },
    removeMediaUrl (e) {
      this.$emit('media-url-remove');
      e.preventDefault();
    }
  }
}
</script>

<style lang="scss">

.media-url-buttons {
  top: 10px;
  right: 10px;
  position: absolute;
  color: lightgray;
  cursor: pointer;

  :hover {
    color: black;
  }
}

.media-url-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;

    .media-url-buttons {
      color: white;

      :hover {
        color: white;
      }
    }
  }
}

</style>

