<template>
  <Loader v-if="isLoading ||isActivating"/>
  <div v-if="!isLoading && !isActivating" class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('shopify_app.page.view.title')">
    </HeaderTitle>

    <div v-if="!error">
      <p>{{ $t('shopify_app.page.view.callback.activation_done', {url: shopUrl}) }}</p>

      <p>{{ $t('shopify_app.page.view.callback.leave_page') }}</p>
    </div>
    <div v-if="error">
      <p>{{ $t('shopify_app.page.view.callback.error') }}</p>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/Page/Loader"
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import ShopifyAppService from "@/services/shopify-app.service";
import {useToast} from "vue-toastification";

export default {
  name: "ShopifyAppActivateView",
  components: {Breadcrumbs, HeaderTitle, Loader},
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      isLoading: true,
      isActivating: false,
      shopUrl: "",
      error: false,
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
      ]
    }
  },
  methods: {
    getAkeneoAppState () {
      return this.$route.query.state;
    },
    callback () {
      this.isActivating = true;

      ShopifyAppService.callback(this.getAkeneoAppState(), this.$route.query)
        .then((response) => {
          this.isActivating = false;
          this.toast.success(this.$i18n.t('shopify_app.page.view.callback.success'));
          this.shopUrl = response.data.shopUrl;
          setTimeout(() => {
            this.$router.push({ name: 'bynder_shopify_guide'})
          }, 2000);
        })
        .catch(error => {
          console.log(error);
          this.error = true;
          this.toast.error(this.$i18n.t('common.errorOccurred'));
          this.isActivating = false;
        });
    },
  },
  beforeMount () {
    this.isLoading = false;
    this.callback();
  },
}
</script>
