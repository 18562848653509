<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <p>
        <a href="https://shopify.dev/docs/api/admin-graphql/2024-07/queries/products#argument-query" target="_blank">{{$t('connection.data.shopify.details_link')}}</a>.
        Examples:
        <ul>
          <li>(sku:product_4) OR (barcode:product_4) OR (tag:[product_4])</li>
          <li>id:gid://shopify/Product/1234567890</li>
        </ul>
      </p>
      <p>{{ $t('connection.data.search') }}</p>
      <TextAreaField
          id="search"
          :label="$t('connection.data.search')"
          v-model="search"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextAreaField from "@/components/Form/TextAreaField.vue";

export default {
  name: "ShopifyProductSearchDataRequest",
  components: {
    TextAreaField,
    GenericDataRequest,
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      search: '',
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getShopifyData(this.connection.id, 'productSearch', {
        search: this.search,
        page: this.page
      })
    }
  },
}
</script>

