import authHeader from "@/services/Auth/auth-header";
import apiClient from "@/services/api-client";

class CompanyService {
    getCompaniesList() {
        let config = {
            headers: authHeader(),
        }

        return apiClient().get('/api/list_companies', config);
    }

    getCompanies(page = 1, params = {}) {
        let config = {
            headers: authHeader(),
            params: {
                "order[id]": 'desc',
                page: page,
                ...params
            }
        }

        return apiClient().get('/api/companies', config);
    }

    getCompany(code) {
        return apiClient().get('/api/companies/' + code, {headers: authHeader()});
    }

    updateOrCreateCompany(company) {
        if (company.id && company.code) {
            return apiClient().patch('/api/companies/' + company.code, company, {headers: authHeader()});
        }

        return apiClient().post('/api/companies', company, {headers: authHeader()});
    }

    removeCompany(code) {
        return apiClient().delete('/api/companies/' + code, {headers: authHeader()});
    }
}

export default new CompanyService();
