<template>
  <div class="form-floating mb-3">
    <input
        type="text"
        class="form-control"
        :class="error ? 'is-invalid' : ''"
        :id="id"
        ref="textInput"
        :placeholder="label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :readonly="readOnly"
        data-bs-toggle="tooltip"
        :data-bs-placement="tooltipPlacement"
        :title="tooltip"
        :required="required"
    />
    <label
        :for="id"
        class="form-label">
      <div class="d-inline-block">{{ label }}</div>
      <div v-if="required" class="d-inline-block">*</div>
    </label>
    <div v-if="error" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import {Tooltip} from "bootstrap"

export default {
  name: "TextField",
  props: {
    id: String,
    label: String,
    modelValue: String,
    tooltip: String,
    error: String,
    required: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    tooltipPlacement: {
      type: String,
      default: "right"
    }
  },
  mounted() {
    const inputElement = this.$refs.textInput;
    new Tooltip(inputElement);
  },
}
</script>
