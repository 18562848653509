import AuthService from '../services/Auth/auth.service';
import jwt_decode from "jwt-decode";

const user = JSON.parse(localStorage.getItem('user'));
let tokenIsValid = false;
if (user) {
    let tokenData = jwt_decode(user.token)
    if (tokenData.exp > Math.floor(new Date().getTime() / 1000)) {
        tokenIsValid = true;
    }
}
const initialState = user && tokenIsValid
    ? {status: {loggedIn: true}, user: jwt_decode(user.token)}
    : {status: {loggedIn: false}, user: null};

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        user(state) {
            return state.user;
        },
        loggedIn(state) {
            return state.status.loggedIn;
        }
    },
    actions: {
        login({commit}, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = jwt_decode(user.token);
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
    }
};
