<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <TextField
          id="assetFamily"
          class="w-100"
          :label="$t('connection.data.akeneo.reference_entity')"
          v-model="referenceEntity"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField.vue";
import {useToast} from "vue-toastification";

export default {
  name: "AkeneoRefEntityAttributeDataRequest",
  components: {
    TextField,
    GenericDataRequest,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      search: '{}',
      referenceEntity: '',
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      if(this.referenceEntity === '') {
        this.toast.error(this.$t('connection.data.akeneo.invalid_reference_entity'));
      } else {
        return ConnectionService.getAkeneoData(this.connection.id, 'ref_entity_attributes', {
          referenceEntity: this.referenceEntity,
        })
      }
    }
  },
}
</script>

