import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import Toast, {POSITION} from "vue-toastification";
import i18n from './i18n'

import 'popper.js';
import 'bootstrap';
import "vue-toastification/dist/index.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@vueform/toggle/themes/default.css';

if(process.env.VUE_APP_STYLE === 'bynder') {
   import('@/assets/styles/bynder_styles.scss');
}

const axios = Axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

const toastOptions = {
    position: POSITION.BOTTOM_RIGHT,
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true
}

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(Toast, toastOptions)
    .use(i18n)
    .mount('#app')
