import authHeader from './Auth/auth-header';
import apiClient from "./api-client";

class VersioningService {
    getVersioning(identifier, type) {
        return apiClient().get('/api/' + type + '/' + identifier + '/history', {headers: authHeader()});
    }
}

export default new VersioningService();
