<template>
  <div class="mb-3">
    <label
        v-if="label"
        class="form-label">
      {{ label }}
    </label>

    <div class="container ms-0">
      <div class="row col-9">
        <div class="col-lg-4 col-md-6" v-for="(mapping, index) in mappings" :key="confInputKey + index">
          <DeleteMappingField
              class="mt-3"
              :id="'mapping-' + index"
              :mapping=mapping
              v-on:mapping-save="saveMapping($event, index)"
              @mapping-remove="removeMapping(index)"
              :errors="this.getMappingErrors(index)"
          />
        </div>
      </div>
    </div>
    <button @click="addMapping" class="m-3 btn btn-primary">
      <i class="bi bi-plus" aria-hidden="true"/>
      {{ $t('form.bynderAkeneo.delete_mappings.add_mapping') }}
    </button>
  </div>
</template>

<script>

import DeleteMappingField from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/DeleteMappingField.vue";

export default {
  name: "DeleteMappingFields",
  components: {DeleteMappingField},
  props: {
    id: String,
    label: String,
    modelValue: Array,
    errors: Object,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confInputKey: Math.random(),
      mappings: this.modelValue
    }
  },
  methods: {
    getMappingErrors (index) {
      if (this.errors && this.errors[index]) {
        return this.errors[index];
      }
      return null;
    },
    addMapping (e) {
      if (!this.mappings) {
        this.mappings = [];
      }

      this.mappings.push({
        asset_family: "",
        bynder_id_attribute_code: "",
        conditions: [],
      });

      e.preventDefault();
    },
    saveMapping (mapping, index) {
      this.mappings[index] = mapping;
      this.updateValue();
    },
    removeMapping (index) {
      this.mappings.splice(index, 1);
      this.updateValue();
    },
    updateValue () {
      this.$emit(`update:modelValue`, this.mappings);
      this.confInputKey = Math.random()
    }
  },
}
</script>
