<template>
  <span class="badge" :class="bgClass">{{ val }}</span>
</template>

<script>
export default {
  name: "BooleanFormatter",
  props: ['val'],
  computed: {
    bgClass() {
      return this.val ? 'bg-success' : 'bg-danger';
    }
  }
}
</script>
