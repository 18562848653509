<template>
  <Loader v-if="isLoaded"/>
  <div v-if="!isLoaded" class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('job_execution.page.view.title')">
      <template v-slot:under-title-area>
        <button class="btn btn-primary position-relative disabled btn-sm status"
                :class="statusClass(jobExecution.status)">
          {{ $t('job_execution.status.' + jobExecution.status) }}
          <span v-if="countWarnings"
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                            {{ countWarnings }}
                        </span>
        </button>
        <div class="d-block text-muted">
          {{ $t('job.job_instance') }} : {{ (jobExecution.jobInstance.label ?? jobExecution.jobInstance.code) }} |
          {{ $t('job.job_instances.job') }} : {{ $t('job.' + jobExecution.jobInstance.jobCode) }}
          <br/>
          {{ $t('common.property.createdAt') }}:
          <DateFormatter :value="jobExecution.createdAt" format="DD/MM/YYYY HH:mm:ss"/> {{ $t('common.by') + ' ' + jobExecution.createdBy }}
          <br/>
          <div v-if="jobExecution.stoppedBy">{{ $t('job_execution.property.stopped_by') }} : {{ jobExecution.stoppedBy }}</div>
        </div>
      </template>
      <template v-slot:button-area>
        <router-link class="btn btn-primary me-1"
                     :to="'/job_instance/' + jobExecution.jobInstance.code + '/view'">
          <i class="bi bi-gear-wide-connected"/>
          {{ $t('job_execution.page.view.button.go_back_to_instance') }}
        </router-link>
        <button :disabled="isDownloadingLogs" class="btn btn-info me-1" @click.prevent="downloadLogs">
          <i class="bi bi-file-text"/>
          {{ $t('job_execution.page.view.button.download_logs') }}
        </button>
        <button v-if="!jobIsFinished"
                :disabled="isStoppingExecution"
                class="btn btn-danger me-1"
                data-bs-toggle="modal"
                data-bs-target="#confirm-execution-stop"
        >
          <i class="bi bi-stop-circle"/>
          {{ $t('job_execution.page.view.button.stop_execution') }}
        </button>
        <ConfirmationModal modal-id="confirm-execution-stop" :title="$t('job_execution.modal.confirm-execution-stop.title')" :action="stopExecution">
          <template v-slot:content>
            {{ $t('job_execution.modal.confirm-execution-stop.text') }}
          </template>
        </ConfirmationModal>
      </template>
    </HeaderTitle>

    <div>
      <table class="table mb-0">
        <thead>
        <tr>
          <th scope="col">Step</th>
          <th scope="col">Status</th>
          <th scope="col">Summary</th>
          <th scope="col">Start</th>
          <th scope="col">End</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="stepExecution in jobExecution.stepExecutions" :key="stepExecution.id">
          <td>{{  $t('job_execution.step.name.' + stepExecution.stepName) }}</td>
          <td>
            <button class="btn btn-primary position-relative disabled btn-sm status"
                    :class="statusClass(stepExecution.status)">
              {{ $t('job_execution.step.status.' + stepExecution.status) }}
              <span v-if="stepExecution.warningCount"
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                  {{ stepExecution.warningCount }}
              </span>
            </button>
          </td>
          <td>
            <table class="table last-borderless">
              <tbody>
              <tr v-for="summaryInfo in decodeStepSummary(stepExecution)" :key="summaryInfo.key">
                <td>{{ $t('job_execution.step.summary.' + summaryInfo.key) }}</td>
                <td>{{ summaryInfo.value }}</td>
              </tr>
              </tbody>
            </table>
          </td>
          <td>
            <DateFormatter :value="stepExecution.createdAt" format="DD/MM/YYYY HH:mm:ss"/>
          </td>
          <td>
            <DateFormatter v-if="stepExecution.endDate" :value="stepExecution.endDate"
                           format="DD/MM/YYYY HH:mm:ss"/>
          </td>
          <td>
            <button v-if="stepExecution.warningCount" type="button" class="btn btn-light"
                    data-bs-toggle="modal" :data-bs-target="'#warning-modal-' + stepExecution.id">
              {{ $t('job_execution.step.button.show_warnings') }}
            </button>
            <StepExecutionWarningModal :step-execution-id="stepExecution.id"/>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="jobExecution.errorMessage" class="alert alert-danger mt-2" role="alert">
        {{ jobExecution.errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../../../components/Page/Loader";
import JobService from "../../../services/job.service";
import HeaderTitle from "../../../components/Page/Content/HeaderTitle";
import DateFormatter from "../../../components/Utils/DateFormatter";
import StepExecutionWarningModal from "../../../components/Job/JobExecution/StepExecutionWarningModal";
import {saveAs} from 'file-saver';
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import {useToast} from "vue-toastification";
import ConfirmationModal from "@/components/Utils/ConfirmationModal";

export default {
  name: "JobExecutionView",
  components: {Breadcrumbs, StepExecutionWarningModal, DateFormatter, HeaderTitle, Loader, ConfirmationModal},
  setup() {
    const toast = useToast();

    return {toast};
  },
  data() {
    return {
      isLoaded: false,
      jobExecution: {},
      timer: null,
      isDownloadingLogs: false,
      isStoppingExecution: false
    }
  },
  computed: {
    inProgressStatus() {
      return ['waiting', 'inProgress', 'mustStop'];
    },
    countWarnings() {
      let warningCount = 0;
      for (let stepExecution of this.jobExecution.stepExecutions) {
        warningCount += stepExecution.warningCount;
      }

      return warningCount;
    },
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'execution_tracker', label: this.$i18n.t('job.execution_tracker'), link: '/execution_tracker'},
        {name: 'execution_view', label: this.$i18n.t('job_execution.page.view.title'), isCurrent: true},
      ]
    },
    jobIsFinished() {
      return !this.inProgressStatus.includes(this.jobExecution.status);
    }
  },
  methods: {
    statusClass(status) {
      switch (status) {
        case 'waiting':
          return 'btn-info';
        case 'inProgress':
          return 'btn-primary';
        case 'success':
          return 'btn-success';
        case 'warning':
          return 'btn-warning';
        case 'error':
          return 'btn-danger';
        case 'mustStop':
        case 'stopped':
          return 'btn-secondary'
        default:
          return 'btn-light';
      }
    },
    decodeStepSummary(stepExecution) {
      let summaryInfos = [];

      let summary = JSON.parse(stepExecution.summary ?? "{}");
      if (!summary) {
        return summaryInfos;
      }

      for (const [key, value] of Object.entries(summary)) {
        summaryInfos.push({
          'key': key,
          'value': value
        })
      }

      return summaryInfos;
    },
    refreshData() {
      JobService.getJobExecution(this.$route.params.id)
          .then((response) => {
            this.jobExecution = response.data;

            if (!this.inProgressStatus.includes(this.jobExecution.status)) {
              clearInterval(this.timer);
            }
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    },
    downloadLogs() {
      this.isDownloadingLogs = true;
      JobService.getExecutionLogs(this.jobExecution.id)
          .then((response) => {
            let blob = new Blob([response.data], {type: "text/plain;charset=utf-8"});
            saveAs(blob, "execution.log");
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error))
          .finally(() => {
            this.isDownloadingLogs = false;
          })
    },
    stopExecution() {
      this.isStoppingExecution = true;
      JobService.stopJobExecution(this.jobExecution.id)
          .then(() => {
            this.toast.success(this.$i18n.t('job_execution.alert.message.stop_success'));
          })
          // TODO Handle error better than this.
          .catch((error) => {
            this.toast.error(this.$i18n.t(error.response.data['hydra:description']));
          })
          .finally(() => {
            this.isStoppingExecution = false;
          })
    }
  },
  beforeMount() {
    this.isLoaded = true;
    JobService.getJobExecution(this.$route.params.id)
        .then((response) => {
          this.jobExecution = response.data;
        })
        // TODO Handle error better than this.
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoaded = false;
        });
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.refreshData()
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
button.status {
  opacity: 1 !important;
}

table {
  .last-borderless {
    margin-bottom: 0;

    tbody {
      tr:last-child {
        border: none;
        border-color: transparent;
        border-style: none;
        border-width: 0;
      }
    }
  }
}
</style>
