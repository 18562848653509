<template>
  <footer class="text-center text-lg-start text-muted mt-5">
    <div class="text-center p-4">
      © {{ year }} Copyright:
      <a class="text-reset fw-bold" target="_blank" :href=getFooterLink()>
          {{ this.getFooterLinktext() }}
      </a>
    </div>
  </footer>
</template>

<script>
import appStyleMixin from "@/mixins/appStyleMixin";

export default {
  name: "Footer",
  mixins: [appStyleMixin],
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style>
</style>
