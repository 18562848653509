<template>
  <HeaderTitle :title="$t('connection.create.title')">
    <template v-slot:under-title-area>
      <p class="d-block text-muted">
        {{ $t('connection.create.select_type') }}
      </p>
    </template>
  </HeaderTitle>

  <div class="container">
    <div class="row">
      <div class="connection-create-type col-xs-12 col-sm-6 col-md-5 col-lg-4 mb-3" @click="$router.push('/connections/create/akeneo')">
        <div class="card">
          <div class="card-body">
            <img src="/images/logo-akeneo.png" class="" alt="Logo Akeneo">
          </div>
        </div>
      </div>
      <div class="connection-create-type col-xs-12 col-sm-6 col-md-5 col-lg-4 mb-3" @click="$router.push('/connections/create/bynder')">
        <div class="card">
          <div class="card-body">
            <img src="/images/logo-bynder.webp" class="" alt="Logo Bynder">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HeaderTitle from "@/components/Page/Content/HeaderTitle";

export default {
  name: 'ConnectionCreate',
  components: {HeaderTitle},
  computed: {
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'connections', label: this.$i18n.t('connection.plural'), link: '/connections'},
      ]
    }
  }
};
</script>

<style lang="scss">

.connection-create-type {
  cursor: pointer;

  .card-body {
    display: block;
    text-align: center;

  }

  img {
    margin: auto;
    height: 180px;
    max-width: 100%;
  }
  :hover {
    background-color: lightgray;
  }
}

</style>

