import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home'
import Login from '@/views/User/Login'
import Profile from '@/views/User/Profile';
import store from '@/store'
import JobInstanceIndex from '@/views/Job/JobInstance/JobInstanceIndex'
import JobInstanceView from "@/views/Job/JobInstance/JobInstanceView";
import JobInstanceEdit from "@/views/Job/JobInstance/JobInstanceEdit";
import JobExecutionIndex from "@/views/Job/JobExecution/JobExecutionIndex";
import JobExecutionView from "@/views/Job/JobExecution/JobExecutionView";
import ShopifyAppActivateView from "@/views/ShopifyApp/ShopifyAppActivateView";
import ShopifyAppCallbackView from "@/views/ShopifyApp/ShopifyAppCallbackView";
import ConfigurationMenu from "@/views/Configuration/ConfigurationMenu";
import UserIndex from "@/views/User/UserIndex";
import UserEdit from "@/views/User/UserEdit";
import CompanyIndex from "@/views/Company/CompanyIndex";
import CompanyEdit from "@/views/Company/CompanyEdit";
import ForgetPassword from "@/views/User/ForgetPassword";
import ResetPassword from "@/views/User/ResetPassword";
import {useToast} from "vue-toastification";
import AkeneoAppActivateView from "@/views/AkeneoApp/AkeneoAppActivateView";
import AkeneoAppCallbackView from "@/views/AkeneoApp/AkeneoAppCallbackView";
import BynderShopifySetupView from "@/views/Guides/BynderShopifySetupView";
import ConnectionIndex from "@/views/Connection/ConnectionIndex";
import connectionEdit from "@/views/Connection/Edit/ConnectionEdit.vue";
import connectionCreate from "@/views/Connection/ConnectionCreate";
import connectionData from "@/views/Connection/Data/ConnectionData.vue";

const routes = [
    // AkeneoApp
    {path: "/akeneo_app/activate", name: 'akeneo_app_activate', component: AkeneoAppActivateView},
    {path: "/akeneo_app/callback", name: 'akeneo_app_callback', component: AkeneoAppCallbackView},
    // Company
    {path: "/companies", name: 'companies', component: CompanyIndex, meta: {adminArea: true}},
    {path: "/company/:code", name: 'company_edit', component: CompanyEdit, meta: {adminArea: true}},
    {path: "/company/create", name: 'company_create', component: CompanyEdit, meta: {adminArea: true}},
    // Configuration
    {path: "/configuration", name: 'configuration', component: ConfigurationMenu, meta: {adminArea: true}},
    // Guides
    {path: "/guides/bynder-shopify-setup", name: 'bynder_shopify_guide', component: BynderShopifySetupView},
    // Job
    {path: "/job_instance", name: 'job_instance', component: JobInstanceIndex},
    {path: "/job_instance/:code/view", name: 'job_instance_view', component: JobInstanceView},
    {path: "/job_instance/create", name: 'job_instance_create', component: JobInstanceEdit, meta: {adminArea: true}},
    {path: "/job_instance/:code/edit", name: 'job_instance_edit', component: JobInstanceEdit},
    {path: "/execution_tracker", name: 'job_tracker', component: JobExecutionIndex},
    {path: "/job_execution/:id", name: 'job_execution_view', component: JobExecutionView},
    // ShopifyApp
    {path: "/shopify_app/activate", name: 'shopify_app_activate', component: ShopifyAppActivateView, meta: {isPublic: true}},
    {path: "/shopify_app/callback", name: 'shopify_app_callback', component: ShopifyAppCallbackView},
    // User
    {path: "/login", name: 'user_login', component: Login, meta: {isPublic: true}},
    {path: "/forget-password", name: 'user_forget-password', component: ForgetPassword, meta: {isPublic: true}},
    {path: "/reset-password/:token", name: 'user_reset-password', component: ResetPassword, meta: {isPublic: true}},
    {path: "/user_profile", name: 'user_profile', component: Profile},
    {path: "/users", name: 'user', component: UserIndex, meta: {adminArea: true}},
    {path: "/user/:id", name: 'user_edit', component: UserEdit, meta: {adminArea: true}},
    {path: "/user/create", name: 'user_create', component: UserEdit, meta: {adminArea: true}},
    // Connection
    {path: "/connections", name: 'connection', component: ConnectionIndex, meta: {adminArea: true}},
    {path: "/connections/create", name: 'connection_create', component: connectionCreate, meta: {adminArea: true}},
    {path: "/connections/create/:type", name: 'connection_create_type', component: connectionEdit, meta: {adminArea: true}},
    {path: "/connections/edit/:type/:id", name: 'connection_edit', component: connectionEdit, meta: {adminArea: true}},
    {path: "/connections/data/:type/:id", name: 'connection_data', component: connectionData, meta: {adminArea: true}},
    // Home
    {path: '/', name: 'home', component: Home},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    let loggedIn = store.getters["auth/loggedIn"];
    let isTokenValid = false;
    let isAdmin = false;
    if (loggedIn) {
        let user = store.getters["auth/user"];
        if (user.exp > Math.floor(new Date().getTime() / 1000)) {
            isTokenValid = true;
        }
        isAdmin = user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_CLIENT_ADMIN');
    }

    if (!to.meta.isPublic && !isTokenValid) {
        sessionStorage.setItem('redirectPath', to.path);
        sessionStorage.setItem('redirectQuery', JSON.stringify(to.query));
        next({ name: 'user_login'});
    } else if (to.meta.adminArea && !isAdmin) {
        const toast = useToast();
        toast.error('You are not allowed to access this page');
        next('/');
    } else {
        next();
    }
});

export default router
