<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <p>
        <a href="https://bynder.docs.apiary.io/#reference/assets/asset-operations/retrieve-assets" target="_blank">{{$t('connection.data.bynder.details_link')}}</a>.
      </p>
      <NumberField
          id="page"
          class="w-100"
          :label="$t('connection.data.bynder.page')"
          v-model="page"
      />
      <p>{{ $t('connection.data.search') }}</p>
      <JsonField
          class=""
          id="search"
          :rows=3
          :modes="['code']"
          v-model="search"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import {useToast} from "vue-toastification";
import JsonField from "@/components/Form/JsonField.vue";
import NumberField from "@/components/Form/NumberField.vue";

export default {
  name: "BynderAssetsDataRequest",
  components: {
    NumberField,
    JsonField,
    GenericDataRequest,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      search: '{}',
      page: 1
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getBynderData(this.connection.id, 'assets', {
        search: this.search,
        page: this.page
      })
    }
  },
}
</script>

