<template>
  <CompanyForm :company-code="$route.params.code" :title="$t('company.title.edit')" :breadcrumbs-items="breadcrumbs"/>
</template>

<script>
import CompanyForm from "@/components/Company/CompanyForm";

export default {
  name: 'CompanyEdit',
  components: {CompanyForm},
  computed: {
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'configuration', label: this.$i18n.t('common.configuration'), link: '/configuration'},
        {name: 'companies', label: this.$i18n.t('company.plural'), link: '/companies'},
        {name: 'code', label: this.$route.params.code, isCurrent: true},
      ]
    }
  }
};
</script>
