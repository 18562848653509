<template>
  <div class="form-floating" :class="error ? 'is-invalid' : ''">
    <select
        class="form-select"
        :id="id"
        :disabled="readOnly"
        :multiple="multiple"
    >
      <option v-if="!multiple"></option>
      <option v-for="option in options"
              :key="option.id"
              :value="option.id"
              :selected="isSelectedOption(option.id)"
      >
        {{ $t(option.text) }}
      </option>
    </select>

    <label :for="id">
      {{ label }}
    </label>
    <div v-if="error" class="invalid-feedback d-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.min.css'

export default {
  name: "SelectField",
  props: {
    id: String,
    label: String,
    modelValue: null,
    error: String,
    options: Array,
    readOnly: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'form.field.select.default_placeholder'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onChange: Function
  },
  data() {
    return {
      value: this.modelValue,
      settings: null
    }
  },
  methods: {
    isSelectedOption(optionId) {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(optionId);
      }

      return this.modelValue === optionId;
    }
  },
  mounted() {
    const jquerySelect = jQuery('#' + this.id);
    jquerySelect.select2({
      theme: 'bootstrap-5',
      dropdownParent: jquerySelect.parent(),
      allowClear: this.readOnly ? false : this.allowClear,
      placeholder: this.$i18n.t(this.placeholder),
    })
        .on('change', (e) => {
          let value = jQuery(e.target).select2('val');
          if (!Array.isArray(value) && Number(value) == value) {
            value = Number(value);
          }

          this.$emit(`update:modelValue`, value);

          if (this.onChange) {
            this.onChange(value);
          }
        });
  }
}
</script>

<style lang="scss">
@import "bootstrap";
@import "node_modules/select2/src/scss/core";
@import "node_modules/select2-bootstrap-5-theme/src/include-all";

.select2-container--bootstrap-5 .select2-selection {
  padding-top: 1.625rem;
}

.select2-container--bootstrap-5 .select2-selection--single {
  height: calc(3.5rem + 2px);
}

.is-invalid {
  .select2-container--bootstrap-5 .select2-selection {
    border-color: $s2bs5-invalid-border-color;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}
</style>
