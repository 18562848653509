<template>
  <div class="form-floating mb-3">
    <input
        type="text"
        class="form-control w-75"
        :class="error ? 'is-invalid' : ''"
        :id="id"
        :placeholder="label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :readonly="readOnly"
    />
    <label
        :for="id"
        class="form-label">
      {{ label }}
    </label>
    <div v-if="humanExpression" class="form-text ms-2">
      {{ humanExpression }}
    </div>
    <div v-if="error" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import cronstrue from 'cronstrue';

export default {
  name: "CronExpressionField",
  props: {
    id: String,
    label: String,
    modelValue: String,
    error: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    humanExpression() {
      try {
        return cronstrue.toString(this.modelValue);
      } catch (e) {
        return '';
      }
    }
  }
}
</script>
