<template>
    <component :is="connectionEditFormName"
               :breadcrumbsItems="breadcrumbs"
               :type="this.type"
               :connectionId="this.connectionId"
               :title="$t('connection.data.' + this.type + '.title')"
    />
</template>

<script>
import CompanyForm from "@/components/Company/CompanyForm.vue";
import AkeneoConnectionData from "@/views/Connection/Data/Akeneo/AkeneoConnectionData.vue";
import BynderConnectionData from "@/views/Connection/Data/Bynder/BynderConnectionData.vue";
import ShopifyConnectionData from "@/views/Connection/Data/Shopify/ShopifyConnectionData.vue";

export default {
  name: 'ConnectionData',
  components: {CompanyForm},
  computed: {
    type () {
      return this.$route.params.type;
    },
    connectionId () {
      return this.$route.params.id;
    },
    connectionEditFormName () {
      switch (this.$route.params.type) {
        case "akeneo":
          return AkeneoConnectionData
        case "bynder":
          return BynderConnectionData
        case "shopify":
          return ShopifyConnectionData
      }

      throw "Invalid connection type";
    },
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'connections', label: this.$i18n.t('connection.plural'), link: '/connections'},
        {name: this.type, label: this.$i18n.t('connection.data.' + this.type + '.title'), link: '/connections/edit/' + this.type + '/' + this.connectionId},
      ]
    }
  }
}
</script>
