<template>
  <nav v-if="isUserLoginRoute" class="navbar navbar-expand-lg navbar-light bg-light mb-3">
    <div class="container-fluid">
      <div>
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="navbar-brand" to="/">
              <img class="navbar-logo" :src="getImage('logo.png')" width="50" height="32">
            </router-link>
          </li>
          <li v-if="loggedIn" class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/">{{ $t('dashboard.title') }}</router-link>
          </li>
          <router-link v-if="loggedIn" class="nav-link" to="/execution_tracker">
            {{ $t('job.execution_tracker') }}
          </router-link>
          <router-link v-if="loggedIn" class="nav-link" to="/job_instance">
            {{ $t('job.job_instances') }}
          </router-link>
          <router-link v-if="loggedIn && isGranted('ROLE_CLIENT_ADMIN')" class="nav-link" to="/connections">
            {{ $t('connection.plural') }}
          </router-link>
        </ul>
      </div>
      <div v-if="isUserLoginRoute">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropstart">
            <a class="nav-link dropdown-toggle" id="nav-user-menu" role="button" data-bs-toggle="dropdown"
               aria-expanded="false">
              <i class="bi bi-person-circle" style="font-size: 1.5rem;"/>
            </a>
            <ul class="dropdown-menu" aria-labelledby="nav-user-menu">
              <div v-if="!loggedIn">
                <li>
                  <router-link class="dropdown-item" aria-current="page" to="/login">
                    {{ $t('security.login') }}
                  </router-link>
                </li>
              </div>
              <div v-if="loggedIn">
                <li>
                  <router-link class="dropdown-item" aria-current="page" to="/user_profile">
                    {{ $t('user.view.userProfile') }}
                  </router-link>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <div v-if="isGranted('ROLE_CLIENT_ADMIN')">
                  <router-link class="dropdown-item" aria-current="page" to="/configuration">
                    {{ $t('common.configuration') }}
                  </router-link>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                </div>
                <li>
                  <a class="dropdown-item" @click="logOut">
                    {{ $t('security.signout') }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapActions, mapState} from "vuex";
import SecurityMixin from "@/mixins/securityMixin";
import appStyleMixin from "@/mixins/appStyleMixin";

export default {
  mixins: [SecurityMixin, appStyleMixin],
  data () {
    return {
      selectedLanguage: ''
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.status.loggedIn,
    }),
    isUserLoginRoute () {
      return !['user_login', 'user_forget-password', 'user_reset-password'].includes(this.$route.name)
    },
  },
  methods: {
    ...mapActions({
      storeLogOut: 'auth/logout',
    }),
    /**
     * Log out and redirect to login page.
     */
    logOut () {
      this.storeLogOut();
      this.$router.push('/login');
    },
  }
}
</script>

<style scoped lang="scss">
.dropstart {
  .dropdown-toggle {
    &:before {
      display: none;
    }
  }
}
</style>
