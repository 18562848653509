import authHeader from './Auth/auth-header';
import apiClient from "./api-client";

class AkeneoAppService {
    activate(url) {
        return apiClient().post('/api/akeneo_app_accesses', {pimUrl: url}, {headers: authHeader()});
    }

    callback(state, code) {
        return apiClient().post('/api/akeneo_app_accesses/' + state + '/generate', {code: code}, {headers: authHeader()});
    }
}

export default new AkeneoAppService();
