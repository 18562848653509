import authHeader from './Auth/auth-header';
import apiClient from "./api-client";

class ConnectionService {
    getConnectionFromId (type, id) {
        return apiClient().get('/api/' + type + '_connections/' + id, {headers: authHeader()});
    }

    getConnections (type, params = {}) {
        let config = {
            headers: authHeader(),
            params: {
                "order[id]": 'desc',
                ...params
            }
        }

        return apiClient().get('/api/' + type + '_connections', config);
    }

    getConnectionData (type, id, endpoint, query = {}) {
        let config = {
            headers: authHeader(),
            params: {
                query: query,
            }
        }

        return apiClient().get(type + '_connections/' + id + '/data/' + endpoint , config);
    }

    updateOrCreateConnection (type, connection) {
        if (connection.companyCode) {
            connection.company = "/api/companies/" + connection.companyCode;
            delete connection.companyCode;
        }

        if (connection.id) {
            return apiClient().patch('/api/' + type + '_connections/' +connection.id, connection, {headers: authHeader()});
        }

        return apiClient().post('/api/' + type + '_connections', connection, {headers: authHeader()});
    }

    testConnection (type, id) {
        return apiClient().get('/test_connection/' + type + '/' + id, {headers: authHeader()});
    }

    removeConnection (type, id) {
        return apiClient().delete('/api/' + type + '_connections/' + id, {headers: authHeader()});
    }

    activateBynderConnection (domain, appStyle, connectionId) {
        return apiClient().post('/api/bynder_app_accesses', {
            domain: domain,
            appStyle: appStyle,
            connection: connectionId,
        }, {headers: authHeader()});
    }

    getAkeneoConnections (params = {}) {
        return this.getConnections('akeneo', params);
    }

    getAkeneoData (id, endpoint, query = {}) {
        return this.getConnectionData('akeneo', id, endpoint, query);
    }

    getBynderConnections (params = {}) {
        return this.getConnections('bynder', params);
    }

    getBynderData (id, endpoint, query = {}) {
        return this.getConnectionData('bynder', id, endpoint, query);
    }

    getShopifyConnections (params = {}) {
        return this.getConnections('shopify', params);
    }

    getShopifyData (id, endpoint, query = {}) {
        return this.getConnectionData('shopify', id, endpoint, query);
    }
}

export default new ConnectionService();
