import {mapState} from "vuex";
import SecurityService from "@/services/security.service";

const securityMixin = {
  computed: {
    ...mapState({
      currentUser: state => state.auth.user,
    })
  },
  methods: {
    isGranted(accessRole) {
      return SecurityService.isGranted(this.currentUser.roles, accessRole);
    }
  }
}

export default securityMixin
